import { ref } from '@vue/composition-api'

export default function useMouse({
  touch = true,
  mouse = true,
  initialValue = { x: 0, y: 0 },
  resetOnTouchEnds = false,
} = {}) {
  const x = ref(initialValue.x)
  const y = ref(initialValue.y)
  const sourceType = ref(null)

  const clickHandlers = []

  const addClickHandler = (callback) => {
    clickHandlers.push(callback)
  }

  const clickHandler = () => {
    clickHandlers.forEach((callback) => callback())
  }

  const mouseHandler = (event) => {
    x.value = event.pageX
    y.value = event.pageY
    sourceType.value = 'mouse'
  }

  const touchHandler = (event) => {
    if (event.touches.length > 0) {
      x.value = event.touches[0].clientX
      y.value = event.touches[0].clientY
      sourceType.value = 'touch'
    }
  }

  const reset = () => {
    x.value = initialValue.x
    y.value = initialValue.y
  }

  window.addEventListener('click', clickHandler, { passive: true })

  if (mouse)
    window.addEventListener('mousemove', mouseHandler, { passive: true })
  if (touch) {
    window.addEventListener('touchstart', touchHandler, { passive: true })
    window.addEventListener('touchmove', touchHandler, { passive: true })

    if (resetOnTouchEnds)
      window.addEventListener('touchend', reset, { passive: true })
  }

  return {
    addClickHandler,
    x,
    y,
    sourceType,
  }
}
