<template>
  <div class="popup">
    <p
      class="popup__text"
      :class="{ 'popup__text--green': good, 'popup__text--red': !good }"
    >
      <span><slot /></span>
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  props: {
    good: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.popup {
  &__text {
    position: relative;
    transform: translateY(-50%) translateX(-50%);
    font-family: 'Lemon';
    -webkit-text-stroke: 1px var(--c-green);
    font-size: 40px;

    span {
      position: absolute;
      z-index: -1;
      -webkit-text-stroke: 5px #000;
      left: 0;
      top: 0;
    }

    &--green {
      color: var(--c-green);
    }

    &--red {
      color: var(--c-red);
    }
  }
}
</style>
