<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.86661 19.2667C0.699943 19.5333 0.733277 19.8667 0.833276 20.1333C0.433277 20.6333 0.799943 21.6667 1.43328 21.3333C5.5666 19.1333 9.7666 16.6333 12.9666 12.5333C14.6666 10.3333 18.1333 5 16.5666 1.6C13.0999 -5.9 2.19994 16.7 0.86661 19.2667Z"
      fill="#DFF2FD"
      stroke="#000000"
      stroke-width="2"
    />
  </svg>
</template>

<script>
import gsap from '@/libs/gsap-bonus'

export default {
  props: {
    toRight: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const tl = gsap.timeline()
    tl.fromTo(this.$el, { opacity: 0 }, { opacity: 1, duration: 0.2 })
    tl.to(this.$el, { opacity: 0, duration: 0.4 })
    tl.to(
      this.$el,
      {
        x: this.toRight ? 30 : -20,
        duration: 0.4,
        ease: 'power4.out',
      },
      0
    )
  },
}
</script>
