<template>
  <div class="lifeBar">
    <heart
      class="lifeBar__heart"
      :delay="i * 0.08"
      v-for="i in Math.max(0, lives)"
      :key="i"
    />
  </div>
</template>

<script>
import Heart from '../svg/heart'
export default {
  props: {
    lives: {
      type: Number,
      required: true,
    },
  },
  components: {
    Heart,
  },
}
</script>

<style lang="scss">
.lifeBar {
  &__heart {
    height: 30px;
    width: 30px;
  }
}
</style>
