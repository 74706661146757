<template>
  <h1 class="e-title transition-in">
    <waterdrop v-if="splash" class="e-title__waterdrop" />
    <waterdrop v-if="splash" class="e-title__waterdrop" />
    <slot />
    <waterdrop v-if="splash" class="e-title__waterdrop" :toRight="true" />
    <waterdrop v-if="splash" class="e-title__waterdrop" :toRight="true" />
  </h1>
</template>

<script>
import gsap from '@/libs/gsap-bonus'
import Waterdrop from '@/components/svg/waterdrop'
import animateOut from '@/mixins/animation-out'

export default {
  data() {
    return {
      splash: false,
      outTl: null,
    }
  },
  mixins: [animateOut],
  components: {
    Waterdrop,
  },
  methods: {
    generateOutTl() {
      this.outTl = gsap.timeline()
      this.outTl.pause()

      this.outTl.to(this.$el, {
        opacity: 0,
        duration: 0.4,
      })
    },
  },
  mounted() {
    this.generateOutTl()
    const tl = gsap.timeline()
    tl.fromTo(
      this.$el,
      { y: 30, opacity: 0 },
      { y: 0, opacity: 1, ease: 'elastic.out(1, 0.8)', duration: 0.6 }
    )
    tl.to(this, { splash: true }, 0.3)
  },
}
</script>

<style lang="scss">
.e-title {
  background: var(--c-white);
  border-radius: 40px;
  display: inline-block;
  padding: 10px 20px;
  position: relative;

  &__waterdrop {
    position: absolute;

    &:nth-child(1) {
      left: -28px;
      top: 0;
      -webkit-transform: matrix3d(
        0,
        -1,
        0,
        0,
        1,
        0,
        0,
        0.001,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
      transform: matrix3d(0, -1, 0, 0, 1, 0, 0, 0.001, 0, 0, 1, 0, 0, 0, 0, 1);
    }

    &:nth-child(2) {
      left: -30px;
      top: 30px;
      -webkit-transform: matrix3d(
        -2,
        0,
        0,
        0.001,
        0,
        -1,
        0,
        0.001,
        0,
        0,
        2,
        0,
        0,
        0,
        0,
        1.5
      );
      transform: matrix3d(
        -2,
        0,
        0,
        0.001,
        0,
        -1,
        0,
        0.001,
        0,
        0,
        2,
        0,
        0,
        0,
        0,
        1.5
      );
    }

    &:nth-child(3) {
      right: -28px;
      top: 0;
      -webkit-transform: matrix3d(
        1.2,
        0,
        0,
        0.001,
        0,
        1.2,
        0,
        0.001,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
      transform: matrix3d(
        1.2,
        0,
        0,
        0.001,
        0,
        1.2,
        0,
        0.001,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }

    &:nth-child(4) {
      right: -28px;
      top: 35px;
      -webkit-transform: matrix3d(
        0.35,
        0.87,
        0,
        0.0001,
        -0.87,
        0.6,
        0,
        0.0001,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
      transform: matrix3d(
        0.35,
        0.87,
        0,
        0.0001,
        -0.87,
        0.6,
        0,
        0.0001,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1
      );
    }
  }
}
</style>
