<template>
  <div class="e-number e-countdown">
    <span>{{ number === 1 ? 'GO' : number - 1 }}</span>
    <span>{{ number === 1 ? 'GO' : number - 1 }}</span>
    <span>{{ number === 1 ? 'GO' : number - 1 }}</span>
  </div>
</template>

<script>
import gsap from '@/libs/gsap-bonus'

export default {
  data() {
    return {
      tl: null,
    }
  },
  props: {
    number: { type: Number },
  },
  mounted() {
    this.tl = gsap.timeline()

    this.tl.fromTo(
      this.$el,
      { scale: 0 },
      { scale: 1, duration: 0.5, ease: 'elastic.out(1, 0.5)' }
    )
    this.tl.fromTo(this.$el, { scale: 1 }, { scale: 0, duration: 0.2 }, 0.75)
  },
  watch: {
    number() {
      this.tl.restart()
      this.tl.play()
    },
  },
}
</script>

<style lang="scss" scoped>
.e-number {
  color: var(--c-white);
  transform: scale(0);

  span {
    position: absolute;
    transform: translate(-50%, -50%);

    &:first-child {
      color: var(--c-black);
      -webkit-text-stroke: 25px #000;
    }

    &:nth-child(2) {
      color: var(--c-black);
      -webkit-text-stroke: 25px #000;
      transform: translate(-50%, -50%) translateY(10px);
    }
  }
}
</style>
