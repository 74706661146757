import { ref } from '@vue/composition-api'

export default function useCountdown(amount = 3) {
  const value = ref(amount)
  let interval

  const start = () => {
    interval = setInterval(() => {
      value.value -= 1
      if (value.value === 0) stop()
    }, 500)
  }

  const stop = () => {
    clearInterval(interval)
  }

  return { start, stop, value }
}
