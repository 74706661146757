import * as THREE from 'three'

import gsap from '@/libs/gsap-bonus'

import quadVert from '@/assets/shaders/quad.glsl'
import hitMarkerFrag from '@/assets/shaders/hit-marker.glsl'

let model

const hitMarkerUniforms = {
  uIntensity: {
    value: 0,
  },
}

const tl = gsap.timeline()
tl.fromTo(
  hitMarkerUniforms.uIntensity,
  { value: 0 },
  { value: 0.8, duration: 0.4 }
)
tl.fromTo(
  hitMarkerUniforms.uIntensity,
  { value: 0.8 },
  { value: 0, duration: 0.4 }
)

function generateModel() {
  const squareGeometry = new THREE.BufferGeometry()

  const vertices = new Float32Array([
    1.0,
    -1.0,
    0.0,
    1.0,
    1.0,
    0.0,
    -1.0,
    1.0,
    0.0,

    -1.0,
    1.0,
    0.0,
    -1.0,
    -1.0,
    0.0,
    1.0,
    -1.0,
    0.0,
  ])

  squareGeometry.setAttribute(
    'position',
    new THREE.BufferAttribute(vertices, 3)
  )

  const material = new THREE.ShaderMaterial({
    vertexShader: quadVert,
    fragmentShader: hitMarkerFrag,
    transparent: true,
    depthWrite: false,
    uniforms: hitMarkerUniforms,
  })
  model = new THREE.Mesh(squareGeometry, material)
  return model
}

export default class Hit extends THREE.Object3D {
  constructor() {
    super()
    if (!model) generateModel()
    this.add(model)
  }

  hit() {
    tl.restart()
  }
}
