import useRaf from '@/plugins/use/use-raf'
import gsap from '@/libs/gsap-bonus'

import assetsLoader from '@/plugins/assets-loader'

export default function useAudioEngine({ musicName, dataName, onComplete }) {
  // let originalAudioData = null // audio data for restore
  let audioData = null
  let startTime = 0
  let isPlaying = false
  let howl = null
  let raf = null

  let callbacks = []

  howl = assetsLoader.get(musicName).subject
  audioData = { ...assetsLoader.get(dataName).subject }

  const addCallback = (cb) => {
    callbacks.push(cb)
  }

  const onPeak = () => {
    callbacks.forEach((cb) => cb())
  }

  const onBeat = () => {
    callbacks.forEach((cb) => cb())
  }

  const volumeUp = () => {
    gsap.to(howl, {
      duration: 0.8,
      volume: 1,
    })
  }

  const update = () => {
    const delta = performance.now() - startTime
    if (Object.keys(audioData)[0] < delta) {
      if (audioData[Object.keys(audioData)[0]] !== -1) onPeak()
      else onBeat()

      delete audioData[Object.keys(audioData)[0]]
    }
  }

  const onEnd = () => {
    onComplete()
    isPlaying = false
  }

  const play = async () => {
    if (isPlaying) return
    // await load(src, dataSrc)
    // howl = data.howl

    howl.on('play', () => {
      raf = useRaf(update, 30)
      startTime = performance.now()
      isPlaying = true
      gsap.to(howl, {
        duration: 0.4,
        volume: 0.5,
      })
    })

    howl.on('end', onEnd)

    howl.seek(0).play()
  }

  const progress = () => {
    return howl.seek() / howl.duration()
  }

  const stop = () => {
    if (!isPlaying) {
      return
    }

    howl.stop()
    howl = null

    isPlaying = false
    startTime = 0
    raf.remove()
  }

  return {
    addCallback,
    play,
    stop,
    progress,
    volumeUp,
  }
}

// let engine

// export default function useAudioEngine() {
//   return engine || (engine = generateAudioEngine())
// }
