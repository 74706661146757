import * as THREE from 'three'
import { ref, watch } from '@vue/composition-api'

import assetsLoader from '@/plugins/assets-loader'
import useThreeEngine from '@/plugins/use/use-three-engine'
import useStage from '@/plugins/use/use-stage'
import events from '@/plugins/events'

import Douche from '@/game/objects/douche'
import Fog from '@/game/objects/fog'
import Hit from '@/game/objects/hit'

// import useStage from '@/plugins/use/use-stage'

const CONFIG = [
  {
    musicName: 'audio-1',
    dataName: 'data-1',
    texture: 'vanille',
    fruits: [
      'grenade',
      'citron',
      'sakura',
      'coco',
      'carthame',
      'vanille',
      'citron_vert',
      'passion',
    ],
  },
  {
    musicName: 'audio-2',
    dataName: 'data-2',
    texture: 'coco',
    fruits: [
      'grenade',
      'citron',
      'sakura',
      'coco',
      'carthame',
      'vanille',
      'citron_vert',
      'passion',
    ],
  },
  {
    musicName: 'audio-3',
    dataName: 'data-3',
    texture: 'passion',
    fruits: [
      'grenade',
      'citron',
      'sakura',
      'coco',
      'carthame',
      'vanille',
      'citron_vert',
      'passion',
    ],
  },
]

export default function useGame() {
  const score = ref(0)
  const lives = ref(3)
  const level = ref(1)
  const stage = ref()

  let fog

  const { scene, camera, orbitControls } = useThreeEngine()

  orbitControls.theta = Math.PI / 2
  orbitControls.radius = 18
  orbitControls.phi = 0.65
  orbitControls.target = new THREE.Vector3(0, 0, -5)

  camera.fov = 60
  camera.updateProjectionMatrix()

  const gameScene = new THREE.Group()
  gameScene.position.z = 4
  scene.add(gameScene)

  const load = async () => {
    await Promise.all([
      assetsLoader.loadFile({
        name: 'empty',
        path: './audio/empty.mp3',
      }),

      assetsLoader.loadFile({
        name: 'click-sound',
        path: './audio/click.mp3',
      }),

      assetsLoader.loadFile({
        name: 'douche',
        path: './models/douche.glb',
      }),
      assetsLoader.loadFile({
        name: 'shampoo',
        path: './models/shampoo.glb',
      }),
      assetsLoader.loadFile({
        name: 'colorant',
        path: './textures/colorant.png',
      }),
      assetsLoader.loadFile({
        name: 'huile-de-palme',
        path: './textures/huile-de-palme.png',
      }),
      assetsLoader.loadFile({
        name: 'paraben',
        path: './textures/paraben.png',
      }),
      assetsLoader.loadFile({
        name: 'vanille',
        path: './textures/VANILLE.png',
      }),
      assetsLoader.loadFile({
        name: 'carreau',
        path: './textures/carreau.png',
      }),
      assetsLoader.loadFile({
        name: 'coco',
        path: './textures/COCO.png',
      }),
      assetsLoader.loadFile({
        name: 'passion',
        path: './textures/PASSION.png',
      }),
      assetsLoader.loadFile({
        name: 'silicone',
        path: './textures/silicone.png',
      }),
      assetsLoader.loadFile({
        name: 'sulfate',
        path: './textures/sulfate.png',
      }),
      assetsLoader.loadFile({
        name: 'threeTone',
        path: './textures/threeTone.jpg',
      }),
      assetsLoader.loadFile({
        name: 'grenade',
        path: './models/grenade.glb',
      }),
      assetsLoader.loadFile({
        name: 'vanille_model',
        path: './models/vanille.glb',
      }),
      assetsLoader.loadFile({
        name: 'citron_vert',
        path: './models/citron_vert.glb',
      }),
      assetsLoader.loadFile({
        name: 'citron',
        path: './models/citron.glb',
      }),
      assetsLoader.loadFile({
        name: 'sakura',
        path: './models/sakura.glb',
      }),
      assetsLoader.loadFile({
        name: 'passion_model',
        path: './models/passion.glb',
      }),
      assetsLoader.loadFile({
        name: 'coco_model',
        path: './models/coco.glb',
      }),
      assetsLoader.loadFile({
        name: 'carthame',
        path: './models/carthame.glb',
      }),
    ])

    const gradientMap = assetsLoader.get('threeTone').subject
    gradientMap.magFilter = THREE.NearestFilter
    gradientMap.minFilter = THREE.NearestFilter

    // await stage.value.load()
  }

  const loadStagesAssets = async () => {
    // only wait for level 1
    await Promise.all([
      assetsLoader.loadFile({
        name: 'audio-1',
        path: './audio/lvl-1.mp3',
      }),

      assetsLoader.loadFile({
        name: 'bonus-sound',
        path: './audio/bonus.mp3',
      }),

      assetsLoader.loadFile({
        name: 'malus-sound',
        path: './audio/malus.mp3',
      }),

      assetsLoader.loadFile({
        name: 'start-sound',
        path: './audio/start.mp3',
      }),

      assetsLoader.loadFile({
        name: 'data-1',
        path: './data/lvl-1.json',
      }),

      assetsLoader.loadFile({
        name: 'slide-r',
        path: './audio/slide-r.mp3',
      }),

      assetsLoader.loadFile({
        name: 'slide-l',
        path: './audio/slide-l.mp3',
      }),
    ])

    assetsLoader.loadFile({
      name: 'audio-2',
      path: './audio/lvl-2.mp3',
    })

    assetsLoader.loadFile({
      name: 'audio-3',
      path: './audio/lvl-3.mp3',
    })

    assetsLoader.loadFile({
      name: 'data-2',
      path: './data/lvl-2.json',
    })

    assetsLoader.loadFile({
      name: 'data-3',
      path: './data/lvl-3.json',
    })
  }

  let hitOverlay

  const init = () => {
    hitOverlay = new Hit()
    scene.add(hitOverlay)

    gameScene.add(new THREE.AmbientLight(0x555555))
    const light = new THREE.PointLight(0xffffff, 1)
    light.position.set(0, 100, 25)
    gameScene.add(light)

    //salle de bain
    const douche = new Douche()
    douche.position.z = 5.25
    gameScene.add(douche)
  }

  fog = new Fog()
  fog.position.z = -6

  gameScene.add(fog)

  const hit = () => {
    hitOverlay.hit()
  }

  const gameOver = () => {
    // console.log('game over')
  }

  const reset = () => {
    level.value = 0
    score.value = 0
    lives.value = 3
  }

  const updateScore = (value) => {
    score.value += value
  }

  const updateLives = (value) => {
    if (lives.value + value >= 0) {
      lives.value += value
    }
  }

  const setLevel = (value) => {
    level.value = value
    stage.value = useStage({
      ...CONFIG[level.value - 1],
    })
    stage.value.addCaptureCallback(hit)
  }

  events.on('score', updateScore)
  events.on('lives', updateLives)

  // setLevel(1)

  watch(
    () => lives.value,
    () => {
      if (lives.value === 0) {
        gameOver()
      }
    }
  )

  return {
    stage,
    level,
    setLevel,
    score,
    lives,
    reset,
    load,
    init,
    fog,
    loadStagesAssets,
    // initCurrentStage,
    // startStage,
    // loadCurrentStage,
  }
}
