import * as THREE from 'three'
import assetsLoader from '@/plugins/assets-loader'

function generateModel() {
  const gradientMap = assetsLoader.get('threeTone').subject

  const model = assetsLoader.get('coco_model').subject.scene.clone()
  model.traverse((child) => {
    if (child.name.includes('body')) {
      child.material = new THREE.MeshToonMaterial({
        color: 0x703819,
        gradientMap,
      })
    }

    if (child.name.includes('inside')) {
      child.material = new THREE.MeshToonMaterial({
        color: 0xfbfffa,
        gradientMap,
      })
    }

    if (child.name.includes('leaf')) {
      child.material = new THREE.MeshToonMaterial({
        color: 0x00cf09,
        gradientMap,
      })
    }

    if (child.name.includes('outline')) {
      child.material = new THREE.MeshBasicMaterial({
        color: 0x000000,
      })
    }
  })

  return model
}

export default class Coco extends THREE.Object3D {
  constructor() {
    super()
    const model = generateModel()

    this.add(model)
  }
}
