import * as THREE from 'three'
import assetsLoader from '@/plugins/assets-loader'

function generateModel() {
  const gradientMap = assetsLoader.get('threeTone').subject

  const model = assetsLoader.get('citron').subject.scene.clone()

  model.traverse((child) => {
    if (child.name.includes('body')) {
      child.material = new THREE.MeshToonMaterial({
        color: 0xffdd01,
        gradientMap,
      })
    }

    if (child.name.includes('leaf')) {
      child.material = new THREE.MeshToonMaterial({
        color: 0x7ef685,
        gradientMap,
      })
    }

    if (child.name.includes('outline')) {
      child.material = new THREE.MeshBasicMaterial({
        color: 0x000000,
      })
    }
  })

  return model
}

export default class Citron extends THREE.Object3D {
  constructor() {
    super()
    const model = generateModel()

    this.add(model)
  }
}
