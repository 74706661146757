import { Howl } from 'howler'

export default class AudioLoader {
  load(file) {
    return new Promise((resolve) => {
      const howl = new Howl({
        src: [file.computedPath],
        volume: 1,
      })

      howl.on('load', () => {
        resolve(howl)
        return howl
      })
    })
  }
}
