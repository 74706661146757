<template>
  <header class="gameHeader">
    <life-bar :lives="lives" class="gameHeader__lives" />
    <div class="gameHeader__score e-game-ui">
      <span>{{ score }}</span
      >{{ score }}
    </div>
  </header>
</template>

<script>
import LifeBar from '../elements/life-bar'
export default {
  props: {
    lives: {
      type: Number,
      required: true,
    },
    score: {
      type: Number,
      required: true,
    },
  },
  components: {
    LifeBar,
  },
}
</script>

<style lang="scss">
.gameHeader {
  display: flex;
  justify-content: space-between;

  &__score {
    color: #fff;
    position: relative;

    span {
      position: absolute;
      -webkit-text-stroke: 5px #000;
      z-index: -1;
    }
  }
}
</style>
