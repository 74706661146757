var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[(_vm.pdfOpen)?_c('pdf-rules',{on:{"close":function () {
        _vm.pdfOpen = false
      }}}):_vm._e(),(_vm.step === 'stage next' && _vm.level <= 2)?_c('next-level',{ref:"nextLevel"}):_vm._e(),(_vm.step === 'game intro')?_c('intro',{ref:"intro",on:{"openPDF":function () {
        _vm.pdfOpen = true
      },"passed":_vm.onIntroPassed}}):_vm._e(),(_vm.step === 'game rules')?_c('rules',{ref:"rules",attrs:{"gameReady":_vm.gameReady},on:{"passed":_vm.onRulesPassed}}):_vm._e(),(_vm.step === 'loading')?_c('loader',{ref:"loader",staticClass:"home__loader"}):_vm._e(),(_vm.step === 'game end')?_c('end',{ref:"end",attrs:{"failed":_vm.lives === 0,"score":_vm.score},on:{"passed":_vm.onEndPassed}}):_vm._e(),(_vm.step === 'game thanks')?_c('thanks',{on:{"replay":_vm.replay}}):_vm._e(),(_vm.step === 'stage intro')?_c('stage-intro',{attrs:{"level":_vm.level,"music":{ title: 'Free', artist: 'Sault' }},on:{"passed":function($event){_vm.step = 'stage play'}}}):_vm._e(),(
      (_vm.step === 'stage intro' || _vm.step === 'stage play') &&
      _vm.level === 1 &&
      _vm.showTips
    )?_c('tutorial',{ref:"tutorial",staticClass:"home__tutorial"}):_vm._e(),_c('transition-group',{attrs:{"name":"popup","tag":"div"}},_vm._l((_vm.notifications),function(n,i){return _c('popup',{key:i + n.position.x + n.position.y,staticClass:"home__popup",style:({ '--x': n.position.x, '--y': n.position.y }),attrs:{"good":n.good}},[_vm._v(" "+_vm._s(n.label)+" ")])}),1),(_vm.step === 'stage play')?_c('game-header',{staticClass:"home__header",attrs:{"score":_vm.score,"lives":_vm.lives}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }