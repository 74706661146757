<template>
  <svg
    width="106"
    height="98"
    viewBox="0 0 106 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56.6755 90.5222L53.3359 86.8011L56.6755 90.5222L90.1146 60.5108C97.721 53.684 101.686 43.685 100.824 33.5007L100.409 28.5993C99.7095 20.3296 95.0018 12.9276 87.8089 8.7876C76.5922 2.33164 62.3037 5.49032 54.8536 16.0728L52.2829 19.7245L51.0471 17.7141C44.0634 6.3533 29.1435 2.88782 17.8673 10.0073C12.5067 13.3919 8.68692 18.7436 7.22839 24.9133L6.09764 29.6965C3.23337 41.8124 7.2163 54.5253 16.4818 62.841L47.3244 90.5222C49.9844 92.9094 54.0156 92.9094 56.6755 90.5222Z"
      fill="#FF4949"
      stroke="black"
      stroke-width="10"
    />
  </svg>
</template>

<script>
import gsap from '@/libs/gsap-bonus'

export default {
  props: {
    delay: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    gsap.fromTo(
      this.$el,
      { scale: 0 },
      { scale: 1, duration: 0.2, delay: this.delay, ease: 'back.out(2)' }
    )
  },
}
</script>
