import * as THREE from 'three'
import assetsLoader from '@/plugins/assets-loader'

// let model

const elements = [
  'silicone',
  'sulfate',
  'colorant',
  'paraben',
  'huile-de-palme',
]

function generateModel() {
  const elt = elements[Math.floor(Math.random() * elements.length)]

  const texture = assetsLoader.get(elt).subject

  const g = new THREE.PlaneBufferGeometry(5, 5)
  const m = new THREE.MeshBasicMaterial({
    map: texture,
    transparent: true,
    depthWrite: false,
    // alphaTest: true,
  })

  const model = new THREE.Mesh(g, m)

  return model
}

export default class Nocive extends THREE.Object3D {
  // constructor() {
  //   super()
  //   this.model = generateModel()
  //   this.add(this.model)
  //   this.blockRotation = true
  // }

  constructor() {
    super()
    this.model = generateModel()
    this.add(this.model)

    this.blockRotation = true
  }
}
