import Vue from 'vue'

import App from './App.vue'

// styles
import './assets/styles/app.scss'

// plugins
import '@/plugins/components'
import VueCompositionAPI from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import vh from '@/plugins/vh'
import events from '@/plugins/events'
import VelocityTracker from '@/libs/gsap-bonus/utils/VelocityTracker'
import gsap from '@/libs/gsap-bonus'

gsap.registerPlugin(VelocityTracker)

Vue.use(VueCompositionAPI)
Vue.use(vh)
Vue.use(events)

Vue.prototype.$gtag = window.gtag

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
