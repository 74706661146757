import * as THREE from 'three'
import assetsLoader from '@/plugins/assets-loader'

function generateModel() {
  const gradientMap = assetsLoader.get('threeTone').subject

  const model = assetsLoader.get('citron_vert').subject.scene.clone()

  model.materials = []
  model.traverse((child) => {
    if (child.name.includes('body')) {
      child.material = new THREE.MeshToonMaterial({
        color: 0xdaff45,
        gradientMap,
      })
    }

    if (child.name.includes('leaf')) {
      child.material = new THREE.MeshToonMaterial({
        color: 0x01cf0a,
        gradientMap,
      })
    }

    if (child.name.includes('outline')) {
      child.material = new THREE.MeshBasicMaterial({
        color: 0x000000,
      })
    }

    const material = child.material

    if (material) {
      const exists = model.materials.filter((m) => {
        return m.uuid === material.uuid
      })[0]

      if (!exists) {
        model.materials.push(child.material)
      }
    }
  })

  return model
}

export default class CitronVert extends THREE.Object3D {
  constructor() {
    super()
    const model = generateModel()

    this.add(model)
  }
}
