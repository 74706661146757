<template>
  <div class="end">
    <div>
      <e-title ref="title" class="end__title">{{
        failed ? 'Bien tenté !' : 'Bravo'
      }}</e-title>
      <e-sub-title class="end__subtitle">{{
        failed ? 'mais Tout n’est pas perdu' : "C'est qui le winner !?"
      }}</e-sub-title>
      <e-border-block ref="block" class="end__box">
        <p class="e-plain-text">
          Pour t'inscrire au tirage au sort, c'est ici ! Tente de remporter un
          super vélo “Made in France”, des abonnements Deezer Premium 3 mois, ou
          des produits <span style="white-space: nowrap">I Love Bio !</span>
        </p>
        <gifts class="end__gifts" />
        <e-input
          v-model="name"
          class="end__input"
          type="text"
          placeholder="Prénom"
          ref="nameIn"
          @input="onInput"
        ></e-input>
        <e-input
          v-model="email"
          class="end__input"
          type="email"
          placeholder="Adresse email"
          ref="mailIn"
          @input="onInput"
        ></e-input>
        <p class="end__error">{{ error }}</p>
        <e-checkbox @input="onInput" v-model="rules" class="end__checkbox"
          >J’accepte le règlement du jeu.</e-checkbox
        >
        <e-checkbox v-model="newsletter" class="end__checkbox"
          >J’accepte de recevoir des informations de la part de la marque et de
          ses partenaires ainsi qu’un code promo sur ma prochaine commande sur
          la boutique en ligne Léa Nature.
        </e-checkbox>
      </e-border-block>
    </div>
    <e-button @click.native="send" ref="button" class="end__cta e-cta-ui"
      >Valider</e-button
    >
  </div>
</template>

<script>
import gsap from '@/libs/gsap-bonus'
import { validateEmail } from '@/utils'
import animateOut from '@/mixins/animation-out'
import axios from 'axios'
import events from '@/plugins/events'

import Gifts from '@/components/elements/gifts'

export default {
  data() {
    return {
      email: '',
      name: '',
      error: '',
      buttonActive: false,
      newsletter: false,
      rules: false,
      outTl: null,
    }
  },
  props: {
    failed: {
      type: Boolean,
      default: false,
    },
    score: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Gifts,
  },
  mixins: [animateOut],
  mounted() {
    events.emit('disable resize', true)
    this.generateOutTl()
    const tl = gsap.timeline()

    tl.to(
      this,
      {
        duration: 0,
        onComplete: () => {
          this.$refs.block.in()
        },
      },
      0.3
    )

    tl.to(
      this,
      {
        duration: 0,
        onComplete: () => {
          this.$refs.nameIn.in()
        },
      },
      0.4
    )
    tl.to(
      this,
      {
        duration: 0,
        onComplete: () => {
          this.$refs.mailIn.in()
        },
      },
      0.45
    )

    tl.fromTo(
      '.end__checkbox',
      {
        opacity: 0,
        y: 10,
      },
      {
        y: 0,
        opacity: 1,
        stagger: 0.1,
      },
      0.6
    )
  },
  beforeDestroy() {
    events.emit('disable resize', false)
  },
  methods: {
    generateOutTl() {
      this.outTl = gsap.timeline()
      this.outTl.pause()

      this.outTl.to(this, {
        duration: 0,
        onComplete: () => {
          this.$refs.title.out()
        },
      })

      this.outTl.to(this, {
        duration: 0,
        onComplete: () => {
          this.$refs.block.out()
        },
      })

      this.outTl.to(this, {
        duration: 0,
        onComplete: () => {
          this.$refs.button.out()
        },
      })

      this.outTl.to('.end__subtitle', { opacity: 0, duration: 0.2 }, 0)

      this.outTl.to(this, {}, 0.4)
    },
    validate() {
      if (!validateEmail(this.email)) {
        this.error = "Merci d'entrer une adresse e-mail valide."
        return false
      } else this.error = ''

      return true
    },
    send() {
      if (this.validate()) {
        const options = {
          method: 'POST',
          url: 'https://i-love-bio-api.herokuapp.com/users',
          headers: { 'Content-Type': 'application/json' },
          data: {
            last_name: this.name,
            email: this.email,
            score: this.score,
            accept_cg: this.rules,
            accept_newsletter: this.newsletter,
            finished: !this.failed,
          },
        }

        axios
          .request(options)
          .then((response) => {
            if (response.data.statusCode === 200) {
              this.$emit('passed')
            } else if (response.data.statusCode === 401) {
              this.error = "Merci d'utiliser une autre adresse mail."
            } else {
              this.error = "Une erreur inconnue s'est produite."
            }
          })
          .catch(() => {
            this.error = "Une erreur inconnue s'est produite."
            // console.error(error)
          })
      }
    },
    onInput() {
      if (
        validateEmail(this.email) &&
        this.name &&
        this.buttonActive === false &&
        this.rules
      ) {
        this.buttonActive = true
        this.$refs.button.in()
      } else if (
        (!validateEmail(this.email) || !this.name || !this.rules) &&
        this.buttonActive === true
      ) {
        this.buttonActive = false
        this.$refs.button.out()
      }
    },
  },
}
</script>

<style lang="scss">
.end {
  align-items: center;
  display: flex;
  flex-direction: column;
  // min-height: calc(100 * var(--vh, 1vh));
  height: 100%;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  padding: 40px;
  padding-top: 26px;
  width: 100%;

  &__box {
    margin-top: 0;
  }

  &__error {
    color: red;
    font-size: 10px;
  }

  &__cta {
    margin-top: 30px;
  }

  &__gifts {
    margin: 8px 0;
  }

  &__input {
    margin-bottom: 15px;
  }

  &__checkbox {
    margin-bottom: 9px;
  }

  &__title {
    white-space: nowrap;
    margin-bottom: 13px;
  }

  &__subtitle {
    margin-bottom: 12px;
  }
}
</style>
