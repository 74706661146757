<template>
  <label class="e-checkbox e-plain-text"
    ><div class="e-checkbox__content"><slot /></div>
    <input ref="input" @input="updateValue" :checked="value" type="checkbox" />
    <span class="e-checkbox__mark">
      <span class="e-checkbox__mark--inner"></span>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: { default: false },
  },
  methods: {
    updateValue() {
      this.$emit('input', this.$refs.input.checked)
    },
  },
}
</script>

<style lang="scss">
.e-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  //   margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
  display: flex;
  align-items: center;
  min-height: 25px;

  &__content {
    font-size: 10px;
    line-height: 12px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover {
    input ~ .e-checkbox__mark--inner {
      background-color: var(--c-white);
    }
  }

  &__mark {
    z-index: 1;
    &:before {
      z-index: -1;
      content: '';
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-color: var(--c-black);
    }
  }

  &__mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: var(--c-white);
    border-radius: 100%;

    &::after {
      content: '';
      position: absolute;
      display: none;
    }

    &::after {
      left: 8px;
      top: 4px;
      width: 8px;
      height: 14px;
      border: solid var(--c-black);
      border-radius: 1px;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &__mark--inner {
    display: block;
    background-color: var(--c-white);
    border: 2px solid var(--c-black);
    height: 100%;
    width: 100%;
    border-radius: 100%;
    z-index: 1;
  }

  input {
    &:checked {
      ~ .e-checkbox__mark {
        background-color: var(--c-white);

        &::after {
          display: block;
        }
      }
    }
  }
}
</style>
