import * as THREE from 'three'
import gsap from '@/libs/gsap-bonus'
import vertexShader from '@/assets/shaders/soap-vertex.glsl'
import fragmentShader from '@/assets/shaders/soap-fragment.glsl'

import useRaf from '@/plugins/use/use-raf'

const POINTS_COUNT = 30

export default class Soap extends THREE.Object3D {
  constructor() {
    super()
    this.geometry = new THREE.BufferGeometry()

    this.material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      transparent: true,
      uniforms: {
        uIntensity: { value: 0 },
        uXPosition: { value: 0 },
        uSide: { value: 0 },
      },
    })

    // const geometry = new THREE.PlaneBufferGeometry(20, 2, 1, 1)
    // const material = new THREE.ShaderMaterial({
    //   vertexShader,
    //   fragmentShader,
    //   transparent: true,
    // })

    // const mesh = new THREE.Mesh(geometry, material)

    // this.add(mesh)

    this.generate()

    this.onMove()

    useRaf(() => {
      //   mesh.material.uniforms.uTime.value++
    })
  }

  onMove(xPosition, toRight) {
    this.timeline?.kill()

    toRight
      ? (this.material.uniforms.uSide.value = 1)
      : (this.material.uniforms.uSide.value = 0)

    this.timeline = new gsap.timeline()

    this.timeline.to(
      this.material.uniforms.uIntensity,
      {
        duration: 0.2,
        value: 1,
      },
      0
    )

    this.timeline.to(
      this.material.uniforms.uXPosition,
      {
        duration: 0.2,
        value: xPosition,
      },
      0
    )

    this.timeline.to(
      this.material.uniforms.uIntensity,
      {
        duration: 0.2,
        value: 0,
      },
      0.2
    )
  }

  generate() {
    const positions = []
    const colors = []
    const sizes = []

    const color = new THREE.Color()

    for (let i = 0; i < POINTS_COUNT; i++) {
      positions.push((Math.random() * 2 - 1) * 5)
      positions.push((Math.random() * 2 - 1) * 0.5)
      positions.push((Math.random() * 2 - 1) * 0)

      color.setHSL(i / POINTS_COUNT, 1.0, 0.5)

      colors.push(color.r, color.g, color.b)

      sizes.push(2)
    }

    this.geometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(positions, 3)
    )
    this.geometry.setAttribute(
      'color',
      new THREE.Float32BufferAttribute(colors, 3)
    )
    this.geometry.setAttribute(
      'size',
      new THREE.Float32BufferAttribute(sizes, 1).setUsage(
        THREE.DynamicDrawUsage
      )
    )

    const particleSystem = new THREE.Points(this.geometry, this.material)

    this.add(particleSystem)
  }
}
