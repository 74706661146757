import * as THREE from 'three'
import assetsLoader from '@/plugins/assets-loader'

function generateModel() {
  const gradientMap = assetsLoader.get('threeTone').subject

  const model = assetsLoader.get('passion_model').subject.scene.clone()
  model.traverse((child) => {
    if (child.name.includes('body')) {
      child.material = new THREE.MeshToonMaterial({
        color: 0x6a005a,
        gradientMap,
      })
    }

    if (child.name === 'inside') {
      child.material = new THREE.MeshToonMaterial({
        color: 0xede0cb,
        gradientMap,
      })
    }

    if (child.name === 'inside1' || child.name === 'grain1') {
      child.material = new THREE.MeshToonMaterial({
        color: 0xf7cb01,
        gradientMap,
      })
    }

    if (child.name === 'grain2') {
      child.material = new THREE.MeshToonMaterial({
        color: 0xb3982c,
        gradientMap,
      })
    }

    if (child.name.includes('outline')) {
      child.material = new THREE.MeshBasicMaterial({
        color: 0x000000,
      })
    }
  })

  return model
}

export default class Passion extends THREE.Object3D {
  constructor() {
    super()
    const model = generateModel()

    this.add(model)
  }
}
