<template>
  <div class="tutorial">
    <e-border-block
      radius="20px"
      class="tutorial__arrow"
      color="#000000"
      ref="right"
      :pressed="pressedLeft"
      ><p class="tutorial__inner">←</p></e-border-block
    >
    <e-border-block
      radius="20px"
      class="tutorial__arrow"
      color="#000000"
      ref="left"
      :pressed="pressedRight"
      ><p class="tutorial__inner">→</p></e-border-block
    >
    <img
      ref="hand"
      class="tutorial__hand"
      src="/images/handpointer.png"
      alt=""
    />
  </div>
</template>

<script>
import gsap from '@/libs/gsap-bonus'
import animateOut from '@/mixins/animation-out'

export default {
  data() {
    return {
      pressedLeft: false,
      pressedRight: false,
      outTl: null,
    }
  },
  mixins: [animateOut],
  methods: {
    generateOutTL() {
      this.outTl = gsap.timeline()
      this.outTl.to(this.$el, { opacity: 0, duration: 0.5 })
      this.outTl.pause()
    },
  },
  mounted() {
    this.$refs.right.in()
    this.$refs.left.in()

    this.generateOutTL()

    const handTl = gsap.timeline({ repeat: -1 })
    handTl.to(this.$refs.hand, {
      x: 20,
      duration: 1,
      ease: 'elastic.out(0.7)',
    })
    handTl.to(this.$refs.hand, {
      x: -40,
      duration: 1,
      ease: 'elastic.out(0.7)',
    })

    const tl = gsap.timeline({ repeat: -1 })
    tl.to(this, {
      duration: 0.3,
      onComplete: () => {
        this.pressedLeft = !this.pressedLeft
      },
    })

    tl.to(this, {
      duration: 0.4,
      onComplete: () => {
        this.pressedLeft = !this.pressedLeft
      },
    })

    tl.to(this, {
      duration: 0.3,
      onComplete: () => {
        this.pressedRight = !this.pressedRight
      },
    })

    tl.to(this, {
      duration: 0.4,
      onComplete: () => {
        this.pressedRight = !this.pressedRight
      },
    })
  },
}
</script>

<style lang="scss">
.tutorial {
  pointer-events: none;
  font-family: 'Lemon';
  display: flex;
  justify-content: space-between;
  padding: 30px 30px;
  position: relative;
  &__inner {
    padding: 10px 10px;
  }

  &__hand {
    position: absolute;
    height: 100px;
    top: 0;
    left: 50%;
  }

  @include media('>tablet') {
    &__hand {
      display: none;
    }
  }

  @include media('<tablet') {
    &__arrow {
      display: none;
    }
  }
}
</style>
