<template>
  <button
    @click="onClick"
    class="e-button"
    :class="{ 'e-button--disabled': disabled }"
  >
    <div class="e-button__inner">
      <span ref="content" class="transition-in"><slot /></span>
    </div>
  </button>
</template>

<script>
import gsap from '@/libs/gsap-bonus'
import animateIn from '@/mixins/animation-in'
import animateOut from '@/mixins/animation-out'

import assetsLoader from '@/plugins/assets-loader'

export default {
  props: {
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      tl: null,
      outTl: null,
    }
  },
  mixins: [animateIn, animateOut],
  methods: {
    onClick() {
      assetsLoader.get('click-sound').subject.play()
      assetsLoader.get('click-sound').subject.volume = 0.1
    },
    generateInTl() {
      this.tl = gsap.timeline()

      this.tl.fromTo(
        this.$el,
        { scaleX: 0 },
        { scaleX: 1, duration: 0.9, ease: 'elastic.out(1, 0.5)' },
        0
      )
      this.tl.to(this.$refs.content, { opacity: 1, duration: 0.2 }, 0.2)
      this.tl.pause()
    },
    generateOutTl() {
      this.outTl = gsap.timeline()

      this.outTl.fromTo(
        this.$el,
        { scaleX: 1 },
        { scaleX: 0, duration: 0.3 },
        0
      )
      this.outTl.to(this.$refs.content, { opacity: 0, duration: 0.2 }, 0)
      this.outTl.pause()
    },
  },
  mounted() {
    this.generateOutTl()
    this.generateInTl()
  },
}
</script>

<style lang="scss">
.e-button {
  background-color: unset;
  border: unset;
  display: inline-block;
  outline: none;
  padding: 0;
  position: relative;
  width: 100%;
  flex-shrink: 0;

  &:active {
    .e-button__inner {
      transform: translate3d(0, 8px, 0);
    }
  }

  &__inner {
    background-color: var(--c-white);
    border: 2px solid var(--c-black);
    border-radius: 30px;
    display: block;
    height: 100%;
    padding: 8px 16px;
    transition: transform 0.1s ease-in-out;
    width: 100%;
  }

  &::before {
    background-color: var(--c-black);
    border-radius: 30px;
    bottom: -8px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  &--disabled {
    color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    -webkit-text-stroke: rgba(0, 0, 0, 0.5);

    .e-button__inner {
      border-color: rgba(0, 0, 0, 0.5);
    }

    &::before {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
