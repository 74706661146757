import * as THREE from 'three'
import assetsLoader from '@/plugins/assets-loader'

let model

function generateModel() {
  const gradientMap = assetsLoader.get('threeTone').subject

  const model = assetsLoader.get('douche').subject.scene
  const carreau = assetsLoader.get('carreau').subject
  carreau.wrapS = THREE.RepeatWrapping
  carreau.wrapT = THREE.RepeatWrapping
  carreau.minFilter = THREE.NearestFilter
  carreau.magFilter = THREE.NearestFilter
  carreau.repeat.set(13, 13)

  model.traverse((child) => {
    if (child.name.includes('douche')) {
      child.material = new THREE.MeshToonMaterial({
        color: 0x568a97,
        gradientMap,
      })
    }

    if (child.name.includes('carrelage')) {
      child.material = new THREE.MeshBasicMaterial({
        // color: 0x81ccde,
        map: carreau,
      })
    }

    if (child.name.includes('holes')) {
      child.material = new THREE.MeshBasicMaterial({
        color: 0x365960,
      })
    }

    if (child.name === 'hole') {
      child.material = new THREE.MeshBasicMaterial({
        color: 0x000000,
      })
    }
  })

  return model
}

export default class Douche extends THREE.Object3D {
  constructor() {
    super()
    if (!model) model = generateModel()
    this.add(model.clone())
  }
}
