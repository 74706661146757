<template>
  <div class="e-input">
    <input
      ref="input"
      :type="type"
      :placeholder="placeholder"
      @input="updateValue"
      :value="value"
    />
  </div>
</template>

<script>
import gsap from '@/libs/gsap-bonus'
import animateIn from '@/mixins/animation-in'

export default {
  data() {
    return {
      tl: null,
    }
  },
  mixins: [animateIn],
  props: {
    value: { default: '' },
    type: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'input',
    },
  },
  mounted() {
    this.generateInTL()
  },
  methods: {
    generateInTL() {
      this.tl = gsap.timeline()

      this.tl.fromTo(
        this.$el,
        {
          scaleX: 0,
        },
        {
          scaleX: 1,
          duration: 0.3,
          ease: 'elastic.out(1, .9)',
        },
        0.1
      )

      this.tl.pause()
    },
    updateValue() {
      this.$emit('input', this.$refs.input.value)
    },
  },
}
</script>

<style lang="scss">
.e-input {
  display: block;
  position: relative;
  width: 100%;
  z-index: 1;

  input {
    border: 1px solid var(--c-black);
    border-radius: 35px;
    display: block;
    outline: none;
    padding: 10px 20px;
    text-align: center;
    width: 100%;
    z-index: 1;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--c-black);
      opacity: 1; /* Firefox */
    }

    &:input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--c-black);
    }

    &::input-placeholder {
      /* Microsoft Edge */
      color: var(--c-black);
    }
  }

  &::after {
    background-color: var(--c-black);
    border-radius: 35px;
    bottom: -5px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}
</style>
