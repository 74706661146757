<template>
  <div class="e-rules">
    <e-title ref="title">Règles</e-title>
    <div>
      <e-border-block
        ref="block1"
        :animationUp="true"
        :step="1"
        class="e-rules__rule"
      >
        <p class="e-plain-text">
          Déplace ton tube de gauche à droite et fais le plein de fruits
        </p>
      </e-border-block>
      <e-border-block
        :animationUp="true"
        ref="block2"
        :step="2"
        class="e-rules__rule"
      >
        <p class="e-plain-text">
          Pour terminer un niveau, remplis le tube en entier
        </p>
      </e-border-block>
      <e-border-block
        :animationUp="true"
        ref="block3"
        :step="3"
        class="e-rules__rule"
      >
        <p class="e-plain-text">
          Si tu collectes les mauvais ingrédients, attention, tu perds une vie !
        </p>
      </e-border-block>
    </div>
    <e-button
      ref="cta"
      :disabled="!gameReady"
      class="e-cta-ui"
      @click.native="$emit('passed')"
      >{{ gameReady ? "c'est parti" : 'chargement' }}</e-button
    >
  </div>
</template>

<script>
import gsap from '@/libs/gsap-bonus'
import animateOut from '@/mixins/animation-out'

export default {
  data() {
    return {
      outTl: null,
    }
  },
  props: {
    gameReady: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [animateOut],
  methods: {
    generateOutTl() {
      this.outTl = gsap.timeline()
      this.outTl.pause()

      this.outTl.to(
        this,
        {
          duration: 0,
          onComplete: () => {
            this.$refs.title.out()
          },
        },
        0
      )

      this.outTl.to(
        this,
        {
          duration: 0,
          onComplete: () => {
            this.$refs.cta.out()
          },
        },
        0
      )

      this.outTl.to(
        this,
        {
          duration: 0,
          onComplete: () => {
            this.$refs.block1.out()
          },
        },
        0.1
      )

      this.outTl.to(
        this,
        {
          duration: 0,
          onComplete: () => {
            this.$refs.block2.out()
          },
        },
        0.2
      )
      this.outTl.to(
        this,
        {
          duration: 0,
          onComplete: () => {
            this.$refs.block3.out()
          },
        },
        0.3
      )

      this.outTl.to(
        this,
        {
          duration: 0.3,
        },
        0.7
      )
    },
  },
  mounted() {
    this.generateOutTl()
    const tl = gsap.timeline()

    tl.to(
      this,
      {
        duration: 0,
        onComplete: () => {
          this.$refs.block1.in()
        },
      },
      0.5
    )

    tl.to(
      this,
      {
        duration: 0,
        onComplete: () => {
          this.$refs.block2.in()
        },
      },
      0.7
    )

    tl.to(
      this,
      {
        duration: 0,
        onComplete: () => {
          this.$refs.block3.in()
        },
      },
      0.9
    )

    tl.to(
      this,
      {
        duration: 0,
        onComplete: () => {
          this.$refs.cta.in()
        },
      },
      1.1
    )
  },
}
</script>

<style lang="scss">
.e-rules {
  align-items: center;
  display: flex;
  flex-direction: column;
  // min-height: calc(100 * var(--vh, 1vh));
  height: 100%;
  justify-content: space-between;
  padding: 40px;
  // padding-bottom: 100px;
  padding-top: 30px;
  width: 100%;

  &__rule:not(:last-child) {
    margin-bottom: 25px;
  }
}
</style>
