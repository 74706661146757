<template>
  <div class="intro">
    <shell-image
      class="intro__madeInFrance"
      :sources="[
        {
          srcset: '/images/france.png',
          media: '(min-width: 200px)',
          type: 'image/png',
        },
      ]"
    />
    <shell-image
      class="intro__vegan"
      :sources="[
        {
          srcset: '/images/vegan.png',
          media: '(min-width: 200px)',
          type: 'image/png',
        },
      ]"
    />
    <shell-image
      class="intro__image"
      ref="logo"
      :sources="[
        {
          srcset: '/images/i-love-bio-logo.png',
          media: '(min-width: 200px)',
          type: 'image/png',
        },
      ]"
    />
    <e-border-block ref="content">
      <p class="e-plain-text">
        Tente de remporter un super vélo “Made in France”, des abonnements
        Deezer Premium ou des super produits
        <span style="white-space: nowrap">I Love Bio !</span>
      </p>
      <gifts class="intro__gifts" />
    </e-border-block>
    <e-button ref="cta" class="e-cta-ui" @click.native="$emit('passed')"
      >Je joue</e-button
    >
    <a
      href="https://i-love-bio.netlify.app/rules.pdf"
      target="_blank"
      ref="noopener noreferrer"
      >Règlement du jeu</a
    >
  </div>
</template>

<script>
import gsap from '@/libs/gsap-bonus'
import animateOut from '@/mixins/animation-out'
import Gifts from '@/components/elements/gifts'

export default {
  data() {
    return {
      baseline: false,
      cta: false,
      outTl: null,
      introFallings: null,
    }
  },
  components: { Gifts },
  mixins: [animateOut],
  methods: {
    generateOutTl() {
      this.outTl = gsap.timeline()

      this.outTl.pause()

      this.outTl.fromTo(
        this.$refs.logo.$el,
        { opacity: 1, scale: 1 },
        { opacity: 0, scale: 0, duration: 0.2, ease: 'ease.out()' },
        0
      )

      this.outTl.to(
        this,
        {
          duration: 0,
          onComplete: () => {
            this.$refs.content.out()
          },
        },
        0
      )

      this.outTl.to(
        this,
        {
          duration: 0,
          onComplete: () => {
            this.$refs.cta.out()
          },
        },
        0
      )
    },
  },
  mounted() {
    this.generateOutTl()

    const tl = gsap.timeline()
    tl.fromTo(this.$el, { opacity: 0 }, { opacity: 1, duration: 0.2 })
    tl.fromTo(
      this.$refs.logo.$el,
      { opacity: 0, scale: 0.4 },
      { opacity: 1, scale: 1, duration: 0.5, ease: 'back.out(2)' },
      0.4
    )

    tl.to(
      this,
      {
        duration: 0,
        onComplete: () => {
          this.$refs.content.in()
        },
      },
      0.6
    )

    tl.to(this, {
      duration: 0,
      onComplete: () => {
        this.$refs.cta.in()
      },
    })

    // console.log(this.$refs.logo.$el)
  },
}
</script>

<style lang="scss">
.intro {
  align-items: center;
  display: flex;
  flex-direction: column;
  // min-height: calc(100 * var(--vh, 1vh));
  height: 100%;
  justify-content: space-between;
  padding: 40px;
  padding-top: 40px;
  padding-bottom: 25px;
  width: 100%;

  &__image {
    max-width: 150px;
  }

  &__gifts {
    margin: 5px 0;
  }

  &__madeInFrance {
    left: 15px;
    max-width: 52px;
    position: absolute;
    top: 15px;
  }

  &__vegan {
    max-width: 92px;
    position: absolute;
    right: 15px;
    top: 15px;
  }
}
</style>
