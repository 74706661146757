<template>
  <div class="webglScene" />
</template>

<script>
import useThreeEngine from '@/plugins/use/use-three-engine'

export default {
  mounted() {
    const { canvas } = useThreeEngine(this.$el)
    this.$el.appendChild(canvas)
  },
}
</script>

<style lang="scss">
.webglScene {
  canvas {
    display: block;
    height: 100%;
    margin: auto;
    opacity: 1;
    visibility: visible;
    width: 100%;
  }
}
</style>
