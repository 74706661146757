import { ref, computed } from '@vue/composition-api'
// import tryOnUnmounted from '@/plugins/use/try-on-unmounted'
import events from '@/plugins/events'

function generateContainerSize(elt) {
  let disabled = false
  const width = ref(0)
  const height = ref(0)
  const aspect = computed(() => width.value / height.value)

  const onResize = () => {
    if (disabled) return
    const containerDimensions = elt.getBoundingClientRect()
    width.value = containerDimensions.width
    height.value = containerDimensions.height
  }

  onResize()

  window.addEventListener('resize', onResize, true)

  events.on('disable resize', (value) => {
    disabled = value
  })

  return { width, height, aspect }
}

let canvasSize

export default function useContainerSize(elt) {
  return canvasSize || (canvasSize = generateContainerSize(elt))
}
