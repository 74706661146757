import * as THREE from 'three'
import useRaf from '@/plugins/use/use-raf'

import Falling from '@/game/falling'

import useThreeEngine from '@/plugins/use/use-three-engine'

export default function useIntro() {
  const fallings = new THREE.Group()

  const fruits = [
    'grenade',
    'citron',
    'sakura',
    'coco',
    'carthame',
    'vanille',
    'citron_vert',
    'passion',
  ]

  const { scene, viewSize, camera, renderer } = useThreeEngine()

  const rtScene = new THREE.Scene()
  const viewport = renderer.getCurrentViewport(new THREE.Vector4())
  const rtTarget = new THREE.WebGLRenderTarget(viewport.z, viewport.w)

  rtScene.add(new THREE.AmbientLight(0x555555))
  const light = new THREE.PointLight(0xffffff, 1)
  light.position.set(0, 100, 25)
  rtScene.add(light)

  const fruitsPlane = new THREE.Mesh(
    new THREE.BoxBufferGeometry(),
    new THREE.MeshBasicMaterial({
      // color: 0xff0000,
      map: rtTarget.texture,
      depthWrite: false,
      depthTest: false,
      transparent: true,
    })
  )

  fruitsPlane.scale.y = viewSize.height
  fruitsPlane.scale.x = viewSize.width
  // fruitsPlane.lookAt(camera.position)

  fruitsPlane.position.z = -camera.position.z

  camera.add(fruitsPlane)
  scene.add(camera)

  let time = performance.now()
  let canSpawn = true

  rtScene.add(fallings)

  const getRandomFruit = () => {
    return fruits[Math.floor(Math.random() * fruits.length)]
  }

  let lastRail
  // faire apparaitre un objet
  const spawnFalling = () => {
    let falling = new Falling({
      isFruit: true,
      type: getRandomFruit(),
    })
    falling.position.y = viewSize.height

    let rail = 0

    do {
      rail = Math.floor(Math.random() * 3)
    } while (rail === lastRail)

    lastRail = rail

    falling.rail = rail
    if (falling.rail === 0) {
      falling.position.x = -viewSize.width / 3
    } else if (falling.rail === 2) {
      falling.position.x = viewSize.width / 3
    }

    fallings.add(falling)
  }

  let deltaTime
  let lastTime = performance.now()
  let lastFruit = 0

  const update = () => {
    deltaTime = time - lastTime
    lastTime = time

    if (time - lastFruit > 800 && canSpawn) {
      spawnFalling()
      lastFruit = time
    }

    fallings.children.forEach((falling) => {
      // faire tomber les objets
      falling.position.y -= deltaTime / 150

      if (!falling.mesh.blockRotation) {
        falling.rotation.y -= deltaTime / 300
        falling.rotation.z = THREE.MathUtils.degToRad(20)
      }

      // dispose si falling dépasse l'écran
      if (falling.position.y < -(viewSize.height / 2) * 2) {
        falling.destroy()
        fallings.remove(falling)
      }
    })

    time = performance.now()

    renderer.setRenderTarget(rtTarget)
    renderer.clear()
    renderer.render(rtScene, camera)
    renderer.setRenderTarget(null)
  }
  const raf60 = useRaf(update, 60)

  const destroy = () => {
    canSpawn = false

    fallings.children.forEach((child) => {
      child.fade()
    })
    setTimeout(() => {
      camera.remove(rtScene)
      scene.remove(camera)
      rtTarget.dispose()
      raf60.remove()
      rtScene.remove(fallings)
    }, 1000)
  }

  return { destroy }
}
