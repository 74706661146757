import Raf from '@solaldr/raf'
// import { onUnmounted } from '@vue/composition-api'

import tryOnUnmounted from '@/plugins/use/try-on-unmounted'

let started = false

export default function useRaf(callback, tick = 60) {
  if (!started) {
    started = true
    Raf.start()
  }

  Raf.addTick(callback, tick)

  const remove = () => {
    Raf.removeTick(callback, tick)
  }

  tryOnUnmounted(remove)

  return { remove }
}
