import useWindowSize from '@/plugins/use/use-window-size'
import { reactive, watch } from '@vue/composition-api'

export default {
  install() {
    const windowSize = reactive({ ...useWindowSize() })

    const onResize = () => {
      // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
      const vh = windowSize.height * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    watch(() => windowSize.height, onResize, { immediate: true })
  },
}
