<template>
  <div class="home">
    <pdf-rules
      v-if="pdfOpen"
      @close="
        () => {
          pdfOpen = false
        }
      "
    />
    <next-level ref="nextLevel" v-if="step === 'stage next' && level <= 2" />
    <intro
      @openPDF="
        () => {
          pdfOpen = true
        }
      "
      ref="intro"
      v-if="step === 'game intro'"
      @passed="onIntroPassed"
    />
    <rules
      ref="rules"
      :gameReady="gameReady"
      v-if="step === 'game rules'"
      @passed="onRulesPassed"
    />
    <loader ref="loader" v-if="step === 'loading'" class="home__loader" />
    <end
      :failed="lives === 0"
      :score="score"
      ref="end"
      v-if="step === 'game end'"
      @passed="onEndPassed"
    />
    <thanks v-if="step === 'game thanks'" @replay="replay" />
    <stage-intro
      v-if="step === 'stage intro'"
      :level="level"
      :music="{ title: 'Free', artist: 'Sault' }"
      @passed="step = 'stage play'"
    />
    <tutorial
      ref="tutorial"
      v-if="
        (step === 'stage intro' || step === 'stage play') &&
        level === 1 &&
        showTips
      "
      class="home__tutorial"
    />
    <!-- <e-title
      v-if="step === 'stage next' && level <= 2"
      ref="nextLevel"
      class="home__nextLevel"
      >Niveau Suivant</e-title
    > -->
    <transition-group name="popup" tag="div">
      <popup
        v-for="(n, i) in notifications"
        :key="i + n.position.x + n.position.y"
        :good="n.good"
        class="home__popup"
        :style="{ '--x': n.position.x, '--y': n.position.y }"
      >
        {{ n.label }}
      </popup>
    </transition-group>
    <game-header
      v-if="step === 'stage play'"
      class="home__header"
      :score="score"
      :lives="lives"
    />
  </div>
</template>

<script>
// import * as THREE from 'three'
// import Stage from '@/game/stage'
// import useThreeEngine from '@/plugins/use/use-three-engine'

import Popup from '@/components/elements/popup'
import Rules from '@/components/elements/rules'
import Intro from '@/components/elements/intro'
import Tutorial from '@/components/elements/tutorial'
import Loader from '@/components/elements/loader'
import NextLevel from '@/components/elements/next-level'
import End from '@/components/elements/end'
import Thanks from '@/components/elements/thanks'
import PdfRules from '@/components/elements/pdf-rules'
import StageIntro from '@/components/elements/stage-intro'
import useGame from '@/plugins/use/use-game'
// import useAudioEngine from '@/plugins/use/use-audio-engine'

import useIntro from '@/plugins/use/use-intro'

import assetsLoader from '@/plugins/assets-loader'

// import useStage from '@/plugins/use/use-stage'

export default {
  name: 'Home',

  components: {
    Popup,
    Rules,
    Intro,
    StageIntro,
    End,
    Thanks,
    Loader,
    NextLevel,
    Tutorial,
    PdfRules,
  },

  setup() {
    // const { load, init, start, score, life, stage, notifications } = useStage({
    //   debugMode: true,
    //   music: '/audio/1.mp3',
    //   musicData: '/data/1.json',
    // })

    // return {
    //   load,
    //   start,
    //   score,
    //   life,
    //   stage,
    //   init,
    //   notifications,
    // }

    const {
      stage,
      lives,
      score,
      level,
      load,
      init,
      initCurrentStage,
      setLevel,
      reset,
      fog,
      loadStagesAssets,
    } = useGame()

    return {
      stage,
      lives,
      fog,
      score,
      level,
      setLevel,
      load,
      init,
      initCurrentStage,
      reset,
      loadStagesAssets,
    }
  },

  // steps:
  // game intro
  // game rules
  // stage loading
  // stage intro
  // stage play
  // stage exit

  data() {
    return {
      step: 'loading',
      gameReady: false,
      showTips: true,
      introFallings: null,
      pdfOpen: false,
    }
  },

  watch: {
    async lives() {
      if (this.lives === 0) {
        await this.stage.leave()
        this.stage.destroy()
        this.endGame()
      }
    },
    async step() {
      if (
        this.step === 'game intro' ||
        this.step === 'game rules' ||
        this.step === 'game thanks'
      ) {
        if (!this.introFallings) this.introFallings = useIntro()
      } else {
        if (this.introFallings) {
          this.introFallings.destroy()
          this.introFallings = null
        }
      }

      if (this.step === 'stage intro') {
        this.stage.enter()
      }

      if (this.step === 'stage play') {
        this.stage.start()
        this.fog.display()
        if (this.showTips) {
          setTimeout(async () => {
            await this.$refs.tutorial.out()
            this.showTips = false
          }, 2000)
        }
      }

      if (this.step === 'stage loading') {
        // await this.stage.load()
        this.stage.init()
        this.stage.playAudio()
        this.step = 'stage intro'
      }
    },
  },

  async mounted() {
    this.$events.on('stage:end', this.onStageEnd)

    await this.load()
    assetsLoader.get('empty').subject.seek(0).play()
    this.init()
    await this.$refs.loader.out()
    this.step = 'game intro'
    this.loadStagesAssets().then(() => {
      this.setLevel(1)
      this.gameReady = true
    })
  },
  computed: {
    notifications() {
      return this.stage ? this.stage.notifications : []
    },
  },
  methods: {
    replay() {
      this.$gtag('event', 'replay', {
        event_category: 'click',
        event_label: 'replay game',
      })
      this.step = 'game rules'
      this.reset()
    },
    async onIntroPassed() {
      this.$gtag('event', 'commencer', {
        event_category: 'click',
        event_label: "click on 'commencer'",
      })

      if (this.step === 'game rules') return
      await this.$refs.intro.out()
      this.step = 'game rules'
    },
    async onRulesPassed() {
      this.$gtag('event', 'cest_parti', {
        event_category: 'click',
        event_label: "click on 'c'est parti'",
      })
      if (this.step === 'stage loading') return
      this.setLevel(1)
      await this.$refs.rules.out()
      this.step = 'stage loading'
    },
    async onEndPassed() {
      this.$gtag('event', 'valider', {
        event_category: 'click',
        event_label: 'send mail & name',
      })
      if (this.step === 'stage loading') return
      await this.$refs.end.out()
      this.step = 'game thanks'
    },
    async onStageEnd() {
      this.step = 'stage next'
      await this.stage.leave()
      this.stage.destroy()
      if (this.level > 2) {
        this.endGame()
        return
      } else {
        await this.$refs.nextLevel.out()
      }
      this.setLevel(this.level + 1)
      setTimeout(() => {
        this.step = 'stage loading'
      }, 0)
    },
    endGame() {
      this.fog.hide()
      this.step = 'game end'
    },
  },
}
</script>

<style lang="scss">
.home {
  flex: 1;
  height: 100%;
  position: inherit;
  overflow: hidden;
  // color: #fff;
  text-align: center;
  z-index: 5;
  border: 4px solid #000;

  &__tutorial {
    position: absolute;
    left: 0;
    bottom: 20%;
    width: 100%;
  }

  &__popup {
    left: var(--x);
    position: absolute;
    top: var(--y);
  }

  &__header {
    left: 0;
    padding: 10px 20px;
    position: absolute;
    top: 16px;
    width: 100%;
  }
  &__nextLevel {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px !important; // exception for override the e-title size
    color: var(--c-blue);
    -webkit-text-stroke: 1px var(--c-blue);
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.popup-enter-active,
.popup-leave-active {
  transition: all 0.6s;
}

.popup-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.popup-enter /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
