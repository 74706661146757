import { Vector3 } from 'three'

export default function toScreenPosition(obj, camera, renderer) {
  var vector = new Vector3()

  var widthHalf = 0.5 * renderer.getContext().canvas.width
  var heightHalf = 0.5 * renderer.getContext().canvas.height

  obj.updateMatrixWorld()
  vector.setFromMatrixPosition(obj.matrixWorld)
  vector.project(camera)

  vector.x = vector.x * widthHalf + widthHalf
  vector.y = -(vector.y * heightHalf) + heightHalf

  return {
    x: vector.x + 'px',
    y: vector.y + 'px',
  }
}
