import Vue from 'vue'

import WebGLScene from '@/components/webgl/scene'
import GameHeader from '@/components/shell/game-header'
import Button from '@/components/elements/button'
import Title from '@/components/elements/title'
import SubTitle from '@/components/elements/sub-title'
import BorderBlock from '@/components/elements/border-block'
import ElementNumber from '@/components/elements/number'
import Input from '@/components/elements/input'
import Checkbox from '@/components/elements/checkbox'

import ShellImage from '@/components/shell/image'

Vue.component('webgl-scene', WebGLScene)
Vue.component('game-header', GameHeader)
Vue.component('e-button', Button)
Vue.component('e-border-block', BorderBlock)
Vue.component('e-title', Title)
Vue.component('e-number', ElementNumber)
Vue.component('e-sub-title', SubTitle)
Vue.component('e-input', Input)
Vue.component('e-checkbox', Checkbox)

Vue.component('shell-image', ShellImage)
