<template>
  <div class="nextLevel">
    <div class="nextLevel__inner" ref="inner">
      <shell-image
        class="nextLevel__image"
        ref="logo"
        :sources="[
          {
            srcset: '/images/i-love-bio-logo.png',
            media: '(min-width: 200px)',
            type: 'image/png',
          },
        ]"
      />
    </div>
  </div>
</template>

<script>
import gsap from '@/libs/gsap-bonus'
import animateOut from '@/mixins/animation-out'

export default {
  data() {
    return {
      outTl: null,
    }
  },
  mixins: [animateOut],
  methods: {
    generateOutTl() {
      this.outTl = gsap.timeline()
      this.outTl.pause()

      this.outTl.fromTo(
        this.$refs.inner,
        { x: 0 },
        { x: 900, duration: 0.5 },
        0
      )
      this.outTl.fromTo(
        this.$refs.logo.$el,
        { x: 0 },
        { x: -900, duration: 0.5 },
        0
      )
    },
  },
  mounted() {
    this.generateOutTl()
    const tl = gsap.timeline()

    tl.fromTo(this.$refs.inner, { x: -900 }, { x: 0, y: 0, duration: 0.5 }, 0)
    tl.fromTo(this.$refs.logo.$el, { x: 900 }, { x: 0, y: 0, duration: 0.5 }, 0)
  },
}
</script>

<style lang="scss">
.nextLevel {
  position: absolute;
  z-index: 99999999;
  width: 200%;
  height: 200%;

  overflow: hidden;
  transform: rotate(-45deg);

  top: -50%;
  left: -50%;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #fff;
    width: 100%;
    overflow: hidden;
  }

  &__image {
    display: block;
    transform: rotate(45deg);
    max-width: 200px;
  }
}
</style>
