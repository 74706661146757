import * as THREE from 'three'

import Grenade from '@/game/objects/grenade'
import Citron from '@/game/objects/citron'
import Sakura from '@/game/objects/sakura'
import Carthame from '@/game/objects/carthame'
import Coco from '@/game/objects/coco'
import Passion from '@/game/objects/passion'
import CitronVert from '@/game/objects/citron-vert'
import Vanille from '@/game/objects/vanille'
// import Bombe from '@/game/objects/bombe'
// import Poison from '@/game/objects/poison'
import Nocive from '@/game/objects/nocive'
import gsap from '@/libs/gsap-bonus'

export default class Falling extends THREE.Group {
  constructor({ isFruit = true, type = 'debug' } = {}) {
    super()
    this.isFruit = isFruit
    this.type = type
    this.canBeCollected = true

    switch (this.type) {
      case 'grenade':
        this.mesh = new Grenade()

        break
      case 'citron':
        this.mesh = new Citron()

        break

      case 'sakura':
        this.mesh = new Sakura()

        break

      case 'carthame':
        this.mesh = new Carthame()

        break

      case 'coco':
        this.mesh = new Coco()

        break

      case 'passion':
        this.mesh = new Passion()

        break

      case 'citron_vert':
        this.mesh = new CitronVert()

        break

      case 'vanille':
        this.mesh = new Vanille()

        break

      case 'nocive':
        this.mesh = new Nocive()

        break

      default:
        this.mesh = new THREE.Mesh(
          new THREE.BoxBufferGeometry(1, 1, 1),
          new THREE.MeshBasicMaterial({
            color: this.isFruit ? 0x00ff00 : 0xff0000,
          })
        )
        break
    }

    this.add(this.mesh)
  }

  get isPoison() {
    return !this.isFruit
  }

  fade() {
    const materials = []

    this.mesh.traverse((child) => {
      const material = child.material

      if (material) {
        const exists = materials.filter((m) => {
          return m.uuid === material.uuid
        })[0]

        if (!exists) {
          child.material.transparent = true
          materials.push(child.material)
        }
      }
    })
    return gsap.to(materials, {
      opacity: 0,
      ease: 'expo.out',
      duration: 0.6,
    })
  }

  vanish = () => {
    this.visible = false
  }

  destroy = () => {
    this.remove(this.mesh)
    // this.remove(this.hitbox);
    // this.mesh.geometry.dispose()
    // this.mesh.material.dispose()
  }
}
