let keyboard

function useKeyboard() {
  let canPress = true
  let handlers = []

  const addHandler = (h) => {
    handlers.push(h)
  }

  const cleanHandlers = () => {
    handlers = []
  }

  const lockDouble = () => {
    canPress = false

    setTimeout(() => {
      canPress = true
    }, 50)
  }
  const onkeydown = (e) => {
    if (!canPress) return
    lockDouble()
    handlers.forEach((h) => {
      h(e)
    })
  }

  window.addEventListener('keydown', onkeydown)

  return { addHandler, cleanHandlers }
}

export default () => (keyboard ? keyboard : (keyboard = useKeyboard()))
