<template>
  <div class="stageIntro">
    <e-title ref="title">niveau {{ level }}</e-title>
    <!-- <div class="stageIntro__music">
      <span>{{ music.title }}</span>
      <span>{{ music.artist }}</span>
    </div> -->
    <e-number class="stageIntro__countdown" :number="countdown" />
  </div>
</template>

<script>
import useCountDown from '@/plugins/use/use-countdown'
export default {
  props: {
    level: {
      type: Number,
      required: true,
    },
    music: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const { start, stop, value: countdown } = useCountDown(4)

    return {
      start,
      stop,
      countdown,
    }
  },

  mounted() {
    this.start()
  },

  watch: {
    countdown() {
      if (this.countdown === 1) this.$refs.title.out()
      if (this.countdown === 0) this.$emit('passed')
    },
  },
}
</script>

<style lang="scss">
.stageIntro {
  // height: calc(100 * var(--vh, 1vh));
  width: 100%;
  height: 100%;
  padding-top: 40px;
  position: relative;

  &__music {
    display: flex;
    flex-direction: column;
  }

  &__countdown {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
