<template>
  <div class="appLoader">
    <shell-image
      class="appLoader__image"
      ref="logo"
      :sources="[
        {
          srcset: '/images/i-love-bio-logo.png',
          media: '(min-width: 200px)',
          type: 'image/png',
        },
      ]"
    />
    <h1>Chargement...</h1>
  </div>
</template>

<script>
import gsap from '@/libs/gsap-bonus'

import animateOut from '@/mixins/animation-out'

export default {
  data() {
    return {
      outTl: null,
    }
  },
  methods: {
    generateOutTl() {
      this.outTl = gsap.timeline()
      this.outTl.pause()
      this.outTl.fromTo(
        this.$el,
        { opacity: 1 },
        { opacity: 0, duration: 0.3 },
        1
      )
    },
  },
  mounted() {
    this.generateOutTl()

    const tl = gsap.timeline({ repeat: -1 })
    tl.to(this.$refs.logo.$el, {
      scale: 1.07,
      duration: 0.9,
      ease: 'sine.inOut',
    })
    tl.to(this.$refs.logo.$el, {
      scale: 1,
      duration: 0.9,
      ease: 'sine.inOut',
    })
  },
  mixins: [animateOut],
}
</script>

<style lang="scss" scoped>
.appLoader {
  height: 100%;
  width: 100%;
  background: var(--c-white);
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__image {
    max-width: 200px;
  }

  h1 {
    font-family: 'Lemon';
    font-size: 20px;
    margin-top: 30px;
  }
}
</style>
