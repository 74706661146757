import * as THREE from 'three'
import { reactive, watch } from '@vue/composition-api'
import useMouse from '@/plugins/use/use-mouse'
import useKeyboard from '@/plugins/use/use-keyboard'
import useThreeEngine from '@/plugins/use/use-three-engine'
import useWindowSize from '@/plugins/use/use-window-size'
import Shampoo from '@/game/objects/shampoo'
import Soap from './objects/soap'
import gsap from '@/libs/gsap-bonus'
import VelocityTracker from '@/libs/gsap-bonus/utils/VelocityTracker'
import assetsLoader from '../plugins/assets-loader'

// const debugModel = new THREE.Mesh(
//   new THREE.BoxBufferGeometry(1, 3, 1),
//   new THREE.MeshNormalMaterial()
// )

export default class Controller extends THREE.Group {
  constructor() {
    super()
    this.disabled = false

    this.mesh = new Shampoo()

    this.soap = new Soap()

    this.add(this.soap)

    this.add(this.mesh)

    this.x = 0

    const mouse = reactive(useMouse())
    this.key = useKeyboard()
    this.key.addHandler(this.onKeyDown)

    watch(() => mouse, this.onMouseMove, { deep: true })

    this.meshScale = 1

    this.callbacks = []

    this.velocityTracker = VelocityTracker.track(this.mesh.position, 'x')[0]
  }

  collect() {
    this.popTimeline?.kill()
    this.popTimeline = new gsap.timeline({
      onUpdate: () => {
        this.mesh.scale.setScalar(this.meshScale)
      },
      onComplete: () => {
        this.meshScale = 1
        this.mesh.scale.setScalar(this.meshScale)
      },
    })

    this.popTimeline.to(this, {
      duration: 0.15,
      meshScale: 1.05,
      ease: 'sine.out',
    })

    this.popTimeline.to(this, {
      duration: 0.15,
      meshScale: 1,
      ease: 'sine.out',
    })
  }

  setProgress(progress) {
    this.mesh.setProgress(progress)
  }

  setTubeTexture(source) {
    this.mesh.setTubeTexture(source)
  }

  resetCam() {
    const { orbitControls } = useThreeEngine()
    gsap.to(
      orbitControls,
      {
        duration: 1,
        ease: 'expo.out',
        theta: 0,
      },
      0
    )
  }

  onMouseMove = (e) => {
    if (this.disabled) return
    const { width } = reactive({ ...useWindowSize() })

    const stepX = width / 3
    let x = Math.floor(e.x / stepX) - 1
    x = Math.min(1, Math.max(-1, x))

    this.updatePosition(x)
  }

  onKeyDown = (e) => {
    if (this.disabled) return
    if (e.keyCode !== 39 && e.keyCode !== 37) return
    let x
    if (e.keyCode == 39) {
      x = this.x + 1
    } else {
      x = this.x - 1
    }
    this.updatePosition(x)
  }

  updatePosition(x) {
    if (x > 1 || x < -1) return
    const { orbitControls, getViewSize } = useThreeEngine()

    const viewSize = getViewSize()
    if (x !== this.x) {
      if (x > this.x) {
        assetsLoader.get('slide-r').subject.stop()
        assetsLoader.get('slide-l').subject.play()
        assetsLoader.get('slide-l').subject.volume = 0.5
      } else {
        assetsLoader.get('slide-l').subject.stop()
        assetsLoader.get('slide-r').subject.play()
        assetsLoader.get('slide-r').subject.volume = 0.5
      }
    }

    if (x != this.x) {
      this.soap.onMove(x * (viewSize.width / 3), x > this.x)
      this.x = x

      this.dashTween?.kill()

      this.dashTween = new gsap.timeline({
        onUpdate: () => {
          const velocity = this.velocityTracker.get('x')
          const rotation = -gsap.utils.clamp(-10, 10, velocity)
          this.mesh.rotation.z = THREE.MathUtils.degToRad(rotation)
        },
        onComplete: () => {
          this.mesh.rotation.z = 0
        },
      })

      this.dashTween.to(
        this.mesh.position,
        {
          duration: 1,
          ease: 'expo.out',
          x: x * (viewSize.width / 3),
        },
        0
      )

      this.dashTween.to(
        orbitControls,
        {
          duration: 1,
          ease: 'expo.out',
          theta: Math.PI / 2 - x * 0.06,
        },
        0
      )
    }
  }
}
