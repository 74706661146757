import { ref, computed } from '@vue/composition-api'
// import tryOnUnmounted from '@/plugins/use/try-on-unmounted'

function generateWindowSize() {
  const width = ref(0)
  const height = ref(0)
  const aspect = computed(() => width.value / height.value)

  const onResize = () => {
    width.value =
      document.documentElement.clientWidth || document.body.clientWidth
    height.value = Math.min(
      window.innerHeight,
      document.documentElement.clientHeight
    )
  }

  onResize()

  window.addEventListener('resize', onResize, true)

  return { width, height, aspect }
}

let windowSize

export default function useWindowSize() {
  return windowSize || (windowSize = generateWindowSize())
}
