import * as THREE from 'three'

import AssetsLoader from '@solaldr/loader'
import { ThreeLoader } from '@solaldr/loader/dist/index.esm'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import AudioLoader from '@/utils/audio-loader'
import JsonLoader from '@/utils/json-loader'

export default new AssetsLoader([
  {
    test: /\.(?:png|jpe?g)$/,
    loader: new ThreeLoader(new THREE.TextureLoader()),
  },
  {
    test: /\.(?:glb|gltf)$/,
    loader: new ThreeLoader(new GLTFLoader()),
  },
  {
    test: /\.(?:mp3)$/,
    loader: new AudioLoader(),
  },
  {
    test: /\.(?:json)$/,
    loader: new JsonLoader(),
  },
])

// assetsLoader.load('/test.glb').then((gltfScene) => {
//   console.log(gltfScene)
// })
