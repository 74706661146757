import * as THREE from 'three'
import assetsLoader from '@/plugins/assets-loader'

import vertexShader from '@/assets/shaders/bottle-vertex.glsl'
import fragmentShader from '@/assets/shaders/bottle-fragment.glsl'

let model
let shaderMat

function generateModel() {
  // const gradientMap = assetsLoader.get('threeTone').subject

  const coco = new THREE.TextureLoader().load('/textures/COCO.png')
  const vanille = new THREE.TextureLoader().load('/textures/VANILLE.png')
  const passion = new THREE.TextureLoader().load('/textures/VANILLE.png')

  coco.magFilter = THREE.NearestFilter
  coco.minFilter = THREE.NearestFilter

  vanille.magFilter = THREE.NearestFilter
  vanille.minFilter = THREE.NearestFilter

  passion.magFilter = THREE.NearestFilter
  passion.minFilter = THREE.NearestFilter

  const textureLogo = new THREE.TextureLoader().load('/textures/LOGOseul.png')

  textureLogo.magFilter = THREE.NearestFilter
  textureLogo.minFilter = THREE.NearestFilter

  const model = assetsLoader.get('shampoo').subject.scene
  model.scale.setScalar(0.75)
  model.traverse((child) => {
    if (child.name.includes('body')) {
      shaderMat = new THREE.ShaderMaterial({
        vertexShader,
        fragmentShader,
        uniforms: {
          uTexture: {
            type: 't',
            value: null,
          },
          uEmptyTexture: {
            type: 't',
            value: textureLogo,
          },
          uProgress: {
            value: 0,
          },
        },
        transparent: true,
        // side: THREE.DoubleSide,
        // alphaTest: 0.5,
      })

      child.material = shaderMat
    }

    if (child.name.includes('outline')) {
      child.material = new THREE.MeshBasicMaterial({
        transparent: true,
        color: 0x000000,
      })
    }

    if (child.name.includes('bouchon')) {
      child.material = new THREE.MeshToonMaterial({
        color: 0xdbd9dc,
      })
    }
  })

  return model
}

export default class Shampoo extends THREE.Object3D {
  constructor() {
    super()
    if (!model) model = generateModel()
    this.add(model.clone())

    this._shampooMat = shaderMat
  }

  setProgress(progress) {
    this._shampooMat.uniforms.uProgress.value = progress
  }

  setTubeTexture(source) {
    const texture = assetsLoader.get(source).subject

    this._shampooMat.uniforms.uTexture.value = texture
  }
}
