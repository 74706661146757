<template>
  <div
    class="e-borderBlock"
    :class="{ 'e-borderBlock--active': pressed, 'e-borderBlock--pin': step }"
    :style="`--color: ${color};--radius: ${radius};`"
  >
    <div class="e-borderBlock__inner">
      <div v-if="step" class="e-superclarendon e-borderBlock__pin">
        <span>{{ step }}</span>
      </div>
      <div ref="content" class="transition-in">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import gsap from '@/libs/gsap-bonus'
import animateIn from '@/mixins/animation-in'
import animateOut from '@/mixins/animation-out'

export default {
  data() {
    return {
      tl: null,
      outTl: null,
    }
  },
  mixins: [animateIn, animateOut],
  props: {
    step: {
      type: Number,
      required: false,
      default: 0,
    },
    animationUp: {
      type: Boolean,
      required: false,
      default: true,
    },
    color: {
      default: 'var(--c-green)',
    },
    radius: {
      default: '35px',
    },
    pressed: {
      default: false,
    },
  },
  methods: {
    generateInTl() {
      this.tl = gsap.timeline()
      if (this.animationUp) {
        this.tl.fromTo(
          this.$el,
          { scaleY: 0 },
          { scaleY: 1, duration: 0.8, ease: 'elastic.out(1, 0.5)' },
          0
        )
      } else {
        this.tl.fromTo(
          this.$el,
          { scaleX: 0 },
          { scaleX: 1, duration: 0.4, ease: 'power2.out' },
          0
        )
      }

      this.tl.to(this.$refs.content, { opacity: 1, duration: 0.2 }, 0.3)
      this.tl.pause()
    },
    generateOutTl() {
      this.outTl = gsap.timeline()
      this.outTl.to(this.$refs.content, { opacity: 0, duration: 0.2 }, 0)
      if (this.animationUp) {
        this.outTl.fromTo(
          this.$el,
          { scaleY: 1 },
          { scaleY: 0, duration: 0.2 },
          0
        )
      } else {
        this.outTl.fromTo(
          this.$el,
          { scaleX: 1 },
          { scaleX: 0, duration: 0.2 },
          0
        )
      }
      this.outTl.pause()
    },
  },
  mounted() {
    this.generateOutTl()
    this.generateInTl()
  },
}
</script>

<style lang="scss">
.e-borderBlock {
  transform-origin: top;

  &__pin {
    // display: none;
    color: var(--color);
    height: 30px;
    left: -15px;
    position: absolute;
    top: -15px;
    width: 30px;
    z-index: 1;

    &::before {
      background: var(--color);
      border-radius: 50px;
      bottom: -4px;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: calc(100%);
      z-index: -1;
    }

    span {
      align-items: center;
      background: var(--c-white);
      border: 3px solid var(--color);
      border-radius: 100%;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
      z-index: 2;
    }
  }

  &::before {
    background-color: var(--color);
    border-radius: var(--radius);
    bottom: -8px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  &__inner {
    background-color: var(--c-white);
    border: 2px solid var(--color);
    border-radius: var(--radius);
    display: inline-block;
    max-width: 300px;
    padding: 9px 16px;
    position: relative;
  }

  &--active {
    .e-borderBlock__inner {
      transform: translate3d(0, 8px, 0);
      // z-index: 9;

      // &::before {
      //   transform: translateY(-8px);
      // }
    }
  }
}
</style>
