import * as THREE from 'three'
import useWindowSize from '@/plugins/use/use-window-size'
import useRaf from '@/plugins/use/use-raf'
// import Stats from 'stats-js'
import { watch, reactive } from '@vue/composition-api'
import OrbitControls from '@/webgl/orbit-controls'
import useContainerSize from '@/plugins/use/use-container-size'
// import GUI from '@/plugins/gui'
import {
  EffectComposer,
  RenderPass,
  EffectPass,
  SMAAEffect,
  SMAAImageLoader,
  SMAAPreset,
  EdgeDetectionMode,
  PredicationMode,
  TextureEffect,
  BlendFunction,
  // ShaderPass,
  // CopyMaterial,
} from 'postprocessing'

function generateThreeEngine() {
  const scene = new THREE.Scene()

  let windowSize = reactive({ ...useWindowSize() })
  const viewSize = reactive({ width: 0, height: 0 })

  const camera = new THREE.PerspectiveCamera(45, windowSize.aspect, 0.1, 1000)

  const orbitControls = new OrbitControls({ object: camera })
  orbitControls.radius = 100

  camera.position.set(0, 0, 20)

  const renderer = new THREE.WebGLRenderer({
    // antialias: true,
    antialias: true,
    alpha: true,
    preserveDrawingBuffer: true,
    // stencil: false, // postprocessing
    // depth: false, // postprocessing
    powerPreference: 'high-performance',
  })

  const canvas = renderer.domElement

  // post processing

  const composer = new EffectComposer(renderer)
  composer.addPass(new RenderPass(scene, camera))

  const loadAntialias = async () => {
    const smaaImageLoader = new SMAAImageLoader()

    return new Promise((resolve) => {
      smaaImageLoader.load(([search, area]) => {
        resolve([search, area])
      })
    })
  }

  const addAntialias = async () => {
    const [search, area] = await loadAntialias()

    const smaaEffect = new SMAAEffect(
      search,
      area,
      SMAAPreset.HIGH,
      EdgeDetectionMode.COLOR
    )

    smaaEffect.edgeDetectionMaterial.setEdgeDetectionThreshold(0.05)
    smaaEffect.edgeDetectionMaterial.setPredicationMode(PredicationMode.DEPTH)
    smaaEffect.edgeDetectionMaterial.setPredicationThreshold(0.002)
    smaaEffect.edgeDetectionMaterial.setPredicationScale(1.0)

    const edgesTextureEffect = new TextureEffect({
      blendFunction: BlendFunction.SKIP,
      texture: smaaEffect.renderTargetEdges.texture,
    })

    const weightsTextureEffect = new TextureEffect({
      blendFunction: BlendFunction.SKIP,
      texture: smaaEffect.renderTargetWeights.texture,
    })

    // const copyPass = new ShaderPass(new CopyMaterial())

    const antialiasPass = new EffectPass(
      camera,
      smaaEffect,
      edgesTextureEffect,
      weightsTextureEffect
    )

    // composer.addPass(copyPass)
    composer.addPass(antialiasPass)
  }

  addAntialias()

  // post processing

  const setContainerElt = (containerElt) => {
    windowSize = reactive({ ...useContainerSize(containerElt) })
  }

  const getViewSize = () => {
    let width, height
    if (camera.type === 'PerspectiveCamera') {
      const distance = camera.position.z
      const vFov = (camera.fov * Math.PI) / 180
      height = 2 * Math.tan(vFov / 2) * distance
      width = height * windowSize.aspect
    } else {
      width = windowSize.width
      height = windowSize.height
    }

    return { width, height }
  }

  const onResize = () => {
    if (camera.type === 'PerspectiveCamera') {
      camera.aspect = windowSize.aspect
    } else {
      camera.left = windowSize.width / -2
      camera.right = windowSize.width / 2
      camera.top = windowSize.height / 2
      camera.bottom = windowSize.height / -2
    }

    camera.updateProjectionMatrix()

    canvas.width = windowSize.width
    canvas.height = windowSize.height

    renderer.setSize(windowSize.width, windowSize.height)
    renderer.setPixelRatio(1)
    composer.setSize(windowSize.width, windowSize.height)

    // console.log(composer)

    const { width, height } = getViewSize()
    viewSize.width = width
    viewSize.height = height
  }

  watch(
    () => windowSize,
    () => {
      onResize()
    },
    { immediate: true, deep: true }
  )

  // const stats = new Stats()
  // stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
  // document.body.appendChild(stats.dom)

  const onFrame = (delta) => {
    // console.log(delta)
    // stats.begin()
    orbitControls.update()
    // renderer.render(scene, camera)
    composer.render(delta)
    // stats.end()
  }

  useRaf(onFrame, 60)

  return {
    scene,
    camera,
    canvas,
    renderer,
    viewSize,
    orbitControls,
    setContainerElt,
    onResize,
    getViewSize,
  }
}

let engine

export default function useThreeEngine(containerElt = null) {
  engine = engine || (engine = generateThreeEngine())
  if (containerElt) {
    engine.setContainerElt(containerElt)
    engine.onResize()
  }
  return engine
}
