import * as THREE from 'three'
import vertexShader from '@/assets/shaders/fog-vertex.glsl'
import fragmentShader from '@/assets/shaders/fog-fragment.glsl'

import useRaf from '@/plugins/use/use-raf'

import gsap from '@/libs/gsap-bonus'

export default class Fog extends THREE.Object3D {
  constructor() {
    super()
    const geometry = new THREE.PlaneBufferGeometry(20, 30, 1, 1)
    this.material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      transparent: true,
      uniforms: {
        uTime: { value: 0 },
        uIntensity: { value: 0 },
      },
    })

    const mesh = new THREE.Mesh(geometry, this.material)

    this.add(mesh)

    useRaf(() => {
      mesh.material.uniforms.uTime.value++
    })
  }

  display() {
    gsap.fromTo(
      this.material.uniforms.uIntensity,
      { value: 0 },
      { value: 1, duration: 0.9 }
    )
  }
  hide() {
    gsap.fromTo(
      this.material.uniforms.uIntensity,
      { value: 1 },
      { value: 0, duration: 0.9 }
    )
  }
}
