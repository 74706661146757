import * as THREE from 'three'

import Falling from '@/game/falling'
import Controller from '@/game/controller'

import useRaf from '@/plugins/use/use-raf'
import useThreeEngine from '@/plugins/use/use-three-engine'
import { ref, watch, computed } from '@vue/composition-api'
import tryOnUnmounted from '@/plugins/use/try-on-unmounted'
import useAudioEngine from '@/plugins/use/use-audio-engine'
import events from '@/plugins/events'
import assetsLoader from '@/plugins/assets-loader'

import gsap from '@/libs/gsap-bonus'

// import Soap from '@/game/objects/soap'
// import GUI from '@/plugins/gui'
import { toScreenPosition } from '@/utils'

const INTERSECTION_TOLERANCE_X = 1
const INTERSECTION_TOLERANCE_Y = 0.1
const TRESHOLD_Y = 0
const MAX_SCORE = 20

export default function useStage({
  debugMode = false,
  musicName,
  dataName,
  texture,
  fruits = ['grenade', 'citron', 'sakura'],
  // packaging = 'debug'
} = {}) {
  const notifications = ref([])
  const score = ref(0)

  let started = false
  let lastRail = -1
  let canCapture = false
  let canSpawn = true

  let controller

  const progress = computed(() => score.value / MAX_SCORE)

  watch(
    () => progress.value,
    () => {
      progress.value
    }
  )

  // const TRESHOLD_Y = -viewSize.height / 4

  // THREE
  const stage = new THREE.Group()
  stage.position.z = 4

  const fallings = new THREE.Group()

  const { viewSize, camera, renderer, scene, orbitControls } = useThreeEngine()

  scene.add(stage)

  const getRandomFruit = () => {
    return fruits[Math.floor(Math.random() * fruits.length)]
  }

  const getRandomMalus = () => {
    return 'nocive'
  }

  const captureCallbacks = []
  const addCaptureCallback = (cb) => {
    captureCallbacks.push(cb)
  }

  const onCapture = (falling) => {
    if (!canCapture) return
    if (falling.isFruit) {
      score.value += 10
      events.emit('score', 10)
      notifications.value.push({
        label: '+10',
        good: true,
        position: toScreenPosition(falling, camera, renderer),
      })
      assetsLoader.get('bonus-sound').subject.play()
      assetsLoader.get('bonus-sound').subject.volume = 1
    } else {
      events.emit('lives', -1)
      assetsLoader.get('malus-sound').subject.play()
      assetsLoader.get('malus-sound').subject.volume = 1
      captureCallbacks.forEach((cb) => cb())
    }

    controller.collect()

    setTimeout(() => {
      notifications.value.shift()
    }, 800)
  }

  const debugThreshold = new THREE.Mesh(
    new THREE.BoxBufferGeometry(1, 1, 1),
    new THREE.MeshBasicMaterial({ color: 0xff0000 })
  )

  if (debugMode) {
    debugThreshold.position.y = TRESHOLD_Y
    stage.add(debugThreshold)
  }

  // watch viewSize
  watch(
    () => viewSize,
    () => {
      if (debugMode) {
        debugThreshold.scale.set(viewSize.width, 0.05, 0.05)
      }
    },
    { deep: true, immediate: true }
  )

  // faire apparaitre un objet
  const spawnFalling = () => {
    if (!canSpawn) return
    if (!started) return
    const isFruit = 1 - Math.round(Math.pow(Math.random(), 2)) // true ou false
    let falling = new Falling({
      isFruit,
      type: isFruit ? getRandomFruit() : getRandomMalus(),
    })
    falling.position.y = viewSize.height

    let rail = 0

    do {
      rail = Math.floor(Math.random() * 3)
    } while (rail === lastRail)

    lastRail = rail

    falling.rail = rail
    if (falling.rail === 0) {
      falling.position.x = -viewSize.width / 3
    } else if (falling.rail === 2) {
      falling.position.x = viewSize.width / 3
    }

    fallings.add(falling)
  }

  const onAudioComplete = async () => {
    controller.disabled = true
    events.emit('stage:end')
  }

  const audio = useAudioEngine({
    musicName,
    dataName,
    onComplete: onAudioComplete,
  })
  audio.addCallback(spawnFalling)

  const init = () => {
    // THREE

    stage.add(fallings)

    //shampoo
    controller = new Controller()
    controller.setTubeTexture(texture)
    controller.position.y = -viewSize.height / 3.5
    controller.disabled = true
    stage.add(controller)

    controller.setProgress(0)
  }

  const start = () => {
    if (started) return
    started = true
    canCapture = true

    controller.disabled = false

    audio.play()
    // audio.volumeUp()
  }

  const playAudio = () => {
    assetsLoader.get('start-sound').subject.play()
    assetsLoader.get('start-sound').volume = 1
  }

  const update = (deltaTime) => {
    // camera.lookAt(cameraLookAt)

    if (!started) return

    controller.setProgress(audio.progress())

    if (audio.progress() > 0.81) canSpawn = false

    fallings.children.forEach((falling) => {
      // faire tomber les objets
      falling.position.y -= deltaTime / 300

      if (!falling.mesh.blockRotation) {
        falling.rotation.y -= deltaTime / 300
        falling.rotation.z -= deltaTime / 900
        // falling.rotation.z = THREE.MathUtils.degToRad(20)
      }

      // check for intersection
      if (
        falling.position.y > TRESHOLD_Y - 3 &&
        falling.position.y < TRESHOLD_Y + INTERSECTION_TOLERANCE_Y
      ) {
        if (
          controller.mesh.position.x >
            falling.position.x - INTERSECTION_TOLERANCE_X &&
          controller.mesh.position.x <
            falling.position.x + INTERSECTION_TOLERANCE_X
        ) {
          onCapture(falling)
          falling.destroy()
          fallings.remove(falling)
        }
      }

      if (falling.position.y < TRESHOLD_Y - 3 && !falling.isFading) {
        falling.isFading = true
        falling.fade()
      }

      // dispose si falling dépasse l'écran
      if (falling.position.y < -viewSize.height / 2) {
        falling.destroy()
        fallings.remove(falling)
      }
    })
  }

  const raf60 = useRaf(update, 60)

  const enter = () => {
    return new gsap.timeline()
      .from(
        stage.position,
        {
          x: viewSize.width,
          duration: 1,
          ease: 'expo.out',
        },
        0
      )
      .from(
        controller.mesh.rotation,
        {
          z: THREE.MathUtils.degToRad(-10),
          duration: 1,
          ease: 'expo.out',
        },
        0
      )
  }

  const leave = () => {
    canCapture = false
    controller.disabled = true
    return new gsap.timeline()
      .to(
        stage.position,
        {
          x: -viewSize.width,
          duration: 1,
          ease: 'expo.out',
        },
        0
      )
      .to(
        controller.mesh.rotation,
        {
          z: THREE.MathUtils.degToRad(10),
          duration: 1,
          ease: 'expo.out',
        },
        0
      )
      .to(
        orbitControls,
        {
          duration: 1,
          ease: 'expo.out',
          theta: Math.PI / 2,
        },
        0
      )
  }

  const destroy = () => {
    scene.remove(stage)
    audio.stop()
    raf60.remove()
  }

  tryOnUnmounted(destroy)

  return {
    // load,
    init,
    start,
    stage,
    notifications,
    addCaptureCallback,
    enter,
    leave,
    destroy,
    playAudio,
  }
}
