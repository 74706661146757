var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"intro"},[_c('shell-image',{staticClass:"intro__madeInFrance",attrs:{"sources":[
      {
        srcset: '/images/france.png',
        media: '(min-width: 200px)',
        type: 'image/png',
      } ]}}),_c('shell-image',{staticClass:"intro__vegan",attrs:{"sources":[
      {
        srcset: '/images/vegan.png',
        media: '(min-width: 200px)',
        type: 'image/png',
      } ]}}),_c('shell-image',{ref:"logo",staticClass:"intro__image",attrs:{"sources":[
      {
        srcset: '/images/i-love-bio-logo.png',
        media: '(min-width: 200px)',
        type: 'image/png',
      } ]}}),_c('e-border-block',{ref:"content"},[_c('p',{staticClass:"e-plain-text"},[_vm._v(" Tente de remporter un super vélo “Made in France”, des abonnements Deezer Premium ou des super produits "),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("I Love Bio !")])]),_c('gifts',{staticClass:"intro__gifts"})],1),_c('e-button',{ref:"cta",staticClass:"e-cta-ui",nativeOn:{"click":function($event){return _vm.$emit('passed')}}},[_vm._v("Je joue")]),_c('a',{ref:"noopener noreferrer",attrs:{"href":"https://i-love-bio.netlify.app/rules.pdf","target":"_blank"}},[_vm._v("Règlement du jeu")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }