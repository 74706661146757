import * as THREE from 'three'
import assetsLoader from '@/plugins/assets-loader'

function generateModel() {
  const gradientMap = assetsLoader.get('threeTone').subject

  const model = assetsLoader.get('carthame').subject.scene.clone()

  model.traverse((child) => {
    if (child.name.includes('body')) {
      child.material = new THREE.MeshToonMaterial({
        color: 0xf6474e,
        gradientMap,
      })
    }

    if (child.name.includes('carthame_body')) {
      child.material = new THREE.MeshToonMaterial({
        color: 0xffc000,
        gradientMap,
      })
    }

    if (
      child.name.includes('grenade_body') ||
      child.name.includes('grenade_grain')
    ) {
      child.material = new THREE.MeshToonMaterial({
        color: 0xf6474e,
        gradientMap,
      })
    }

    if (child.name.includes('grenade_inside')) {
      child.material = new THREE.MeshToonMaterial({
        color: 0xeee1cb,
        gradientMap,
      })
    }

    if (child.name.includes('grenade_tail')) {
      child.material = new THREE.MeshToonMaterial({
        color: 0xffdd01,
        gradientMap,
      })
    }

    if (child.name.includes('leaf')) {
      child.material = new THREE.MeshToonMaterial({
        color: 0x00cf09,
        gradientMap,
      })
    }

    if (child.name.includes('outline')) {
      child.material = new THREE.MeshBasicMaterial({
        color: 0x000000,
      })
    }
  })

  return model
}

export default class Carthame extends THREE.Object3D {
  constructor() {
    super()
    const model = generateModel()

    this.add(model)
  }
}
