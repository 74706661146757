import * as THREE from 'three'

/**
 * @class EuclideanSphere
 */
class EuclideanSphere {
  /**
   * Convert the geocoords in a cartesian repear
   * @param {{phi: float, theta: float}} param1
   * @param {float} radius
   * @param {THREE.Vector3} target
   * @returns {THREE.Vector3()}
   */
  static getCartesianCoords(
    { phi = 0, theta = 0 } = {},
    radius = 1,
    target = new THREE.Vector3()
  ) {
    target.x = radius * Math.cos(phi) * Math.cos(theta)
    target.y = radius * Math.sin(phi)
    target.z = radius * Math.cos(phi) * Math.sin(theta)
    return target
  }

  /**
   * Get spherical coord form a position
   * @param {THREE.Vector3} position The position you want to transform in spherical coord
   * @param {THREE.Vector3} center
   * @return {{phi: float, theta: float}}
   */
  static getEnclideanCoords(position, center = new THREE.Vector3()) {
    const diff = position.clone().sub(center)
    const radius = position.distanceTo(center)

    return {
      phi: Math.acos(diff.y / radius) - Math.PI / 2,
      theta: Math.atan(diff.x / diff.z) + Math.PI / 2,
    }
  }
}

/**
 * @author solaldr
 * @param
 */
class OrbitControls {
  constructor({
    enabled = true,
    object = null,
    target = new THREE.Vector3(),
    phi = 0,
    theta = 0,
    radius = null,
    look = null,
  } = {}) {
    this.object = object
    this.enabled = enabled
    this.target = target
    this.look = look || this.target
    this.phi = phi
    this.theta = theta
    this.radius = radius
    this.axeRotation = 0

    if (enabled) {
      this.computePosition()
      this.object.lookAt(this.look)
    }
  }

  computePosition() {
    EuclideanSphere.getCartesianCoords(
      {
        phi: this.phi,
        theta: this.theta,
      },
      this.radius,
      this.object.position
    )
    this.object.position.sub(this.target)
  }

  computeDirection() {
    this.direction.x =
      this.object.position.x + 0.5 * Math.sin(this.phi) * Math.cos(this.theta)
    this.direction.y = this.object.position.y + 0.5 * Math.cos(this.phi)
    this.direction.z =
      this.object.position.z + 0.5 * Math.sin(this.phi) * Math.sin(this.theta)
  }

  computeZoom() {
    this.object.position.copy(
      this.object.position
        .sub(this.target)
        .normalize()
        .multiplyScalar(this.radius)
    )
  }

  computeRadius() {
    this.radius = this.object.position.distanceTo(this.target)
  }

  update() {
    if (this.enabled) {
      this.computePosition()
      this.object.lookAt(this.look)
      this.object.rotateOnWorldAxis(
        this.target.clone().sub(this.object.position).normalize(),
        this.axeRotation
      )
    }
  }
}

export default OrbitControls
