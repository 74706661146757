<template>
  <div class="thanks">
    <e-title class="thanks__title">Merci</e-title>
    <e-border-block ref="block" class="thanks__box">
      <p class="e-plain-text">
        Ta participation a bien été prise en compte, à bientôt sur Deezer avec
        <span style="white-space: nowrap">I Love Bio !</span>
      </p>
    </e-border-block>
    <a
      target="_blank"
      ref="noopener"
      href="https://www.instagram.com/ilovebiobyleanature/"
    >
      <shell-image
        class="thanks__image"
        ref="logo"
        @click="trackEnd"
        :sources="[
          {
            srcset: '/images/i-love-bio-logo.png',
            media: '(min-width: 200px)',
            type: 'image/png',
          },
        ]"
      />
    </a>
    <e-button
      @click.native="copy"
      :disabled="shared"
      ref="buttonShare"
      class="thanks__cta thanks__share e-cta-ui"
      >{{ shareButton }}</e-button
    >
    <e-button
      @click.native="$emit('replay')"
      ref="buttonReplay"
      class="thanks__cta e-cta-ui"
      >Rejouer</e-button
    >
  </div>
</template>

<script>
import gsap from '@/libs/gsap-bonus'
export default {
  data() {
    return {
      shareButton: 'Partager',
      shared: false,
    }
  },
  mounted() {
    const tl = gsap.timeline()
    tl.to(
      this,
      {
        onComplete: () => {
          this.$refs.block.in()
        },
      },
      0.1
    )

    tl.to(
      this,
      {
        onComplete: () => {
          this.$refs.buttonShare.in()
        },
      },
      0.2
    )

    tl.to(
      this,
      {
        onComplete: () => {
          this.$refs.buttonReplay.in()
        },
      },
      0.3
    )
  },
  methods: {
    trackEnd() {
      this.$gtag('event', 'thanks', {
        event_category: 'click',
        event_label: 'click on Logo thanks',
      })
    },
    copy() {
      this.$gtag('event', 'share', {
        event_category: 'click',
        event_label: 'click on share',
      })
      navigator.clipboard
        .writeText('https://www.deezer.com/app/ilovebiolejeu')
        .then(() => {
          /* clipboard successfully set */
          this.shareButton = 'Lien copié !'
          this.shared = true
        })
    },
  },
}
</script>

<style lang="scss">
.thanks {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 40px;
  padding-top: 26px;
  width: 100%;

  @include media('>desktop') {
    .thanks__share {
      display: none;
    }
  }

  &__image {
    margin: auto;
    max-width: 100px;
    padding: 10px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__cta {
    margin-bottom: 20px;
  }
}
</style>
