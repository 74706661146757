<template>
  <picture class="shellImage">
    <source v-for="(source, i) in sources" :key="i" v-bind="source" />
    <img v-bind="$attrs" :loading="loading" />
  </picture>
</template>

<script>
// https://github.com/mfranzke/loading-attribute-polyfill
export default {
  inheritAttrs: false,
  props: {
    /** Control the loading strategy of the Image. Could be 'lazy', 'eager' or 'auto'.
     * Source: https://css-tricks.com/native-lazy-loading/
     */
    loading: {
      type: String,
      default: 'lazy',
      validator(value) {
        return ['lazy', 'eager', 'auto'].includes(value)
      },
    },
    /**
     * Array of source elements.
     * Each source requires 'media', 'srcset' and 'type' attributes.
     * Source: https://developer.mozilla.org/fr/docs/Web/HTML/Element/Source
     *
     * @example
     * <shell-image
     *    :sources: [{
     *        srcset: 'https://via.placeholder.com/501',
     *        media: '(min-width: 769px)',
     *        type:'image/png'
     *    }]
     * />
     *
     *
     */
    sources: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
.shellImage {
  display: inline-block;
  img {
    height: 100%;
    width: 100%;
  }
}
</style>
